import React, { useState } from 'react';
import apiClient from '../services/apiClient';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import classNames from 'classnames';

const LoginForgot = ( props ) => {
	// console.log( props );
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState();

	const handleSubmit = ( e ) => {
		e.preventDefault();
		apiClient( {
			method: 'post',
			url: '/api/v1/forgot',
			data: {
				email: email
			} 
		} )
		.then( response => {
			toast.success( response.data.message );
			setEmail( '' );
		})
		.catch( ( error ) => {
			toast.error( error.response.data.message );
			// setMessage( { type: error.code, message:  } );
		} );
	}
	return (
		<div className="inner-layout login-layout">
      <div className="form-wrapper">
        <h1>Forgot</h1>
				<form onSubmit={ handleSubmit }>
					{ ( message ) ? <p className={ classNames( [ "form-message", message.type ] ) } >{ message.message }</p> : '' }
					<label>Email address<br />
						<input
							type="email"
							name="email"
							placeholder="Email"
							value={ email }
							onChange={ e => setEmail( e.target.value ) }
							autoComplete="username"
							required
						/>
					</label>
					<button type="submit">Reset</button>
				</form>
				<Link to="/">Back to Login</Link>
      </div>
    </div>
	);
}

export default LoginForgot;