import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import apiClient from '../services/apiClient';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { Link } from 'react-router-dom';

import { ReactComponent as IconDelete } from '../images/icon-delete.svg';

const AdminUsers = ( props ) => {
	const [users, setUsers] = useState( [] );
	const [hasError, setHasError] = useState( false );
	const [isLoaded, setIsLoaded] = useState( false );
	const [message, setMessage] = useState( 'You are not logged in' );
	const [meta, setMeta] = useState( undefined );
	const [currentPage, setCurrentPage] = useState( 1 );

	useEffect( () => {
		fetchUsers();
	}, [ props.loggedIn, currentPage ]);

	const updatePageNumber = ( e, newPageNumber ) => {
		setCurrentPage( newPageNumber );
	}

	const fetchUsers = () => {
		if( props.loggedIn ) {
			apiClient.get( '/api/v1/users?page=' + currentPage, { params: { includeAllUsers: true } } )
				.then( response => {
					setUsers( response.data.data );
					setMeta( response.data.meta );
					setIsLoaded( true );
				})
				.catch( ( error ) => {
					setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}
	}

	const handleDeleteUserClick = ( id ) => {
		if( id ) {
			apiClient( {
				method: 'delete',
				url: '/api/v1/users/' + id,
				data: {}
			} )
				.then( response => {					
					if( response.status === 200 ) {
						if( response.data === 1 ) {
							toast.success( "User has been deleted" );
							fetchUsers();
						}
						else {
							toast.error( "User has failed to be deleted" );
						}
					}
					// setIsLoaded( true );
				})
				.catch( ( error ) => {
					setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}	
	}

	if( props.loggedIn && hasError === false ) {
		const usersList = users.map( ( user ) => 
			<tr key={ user.id }>
				<td>{ user.firstName } { user.lastName }</td>
				<td>{ user.email }</td>
				<td>{ user.phone }</td>
				<td>{ user.companyName }</td>
				<td name="link"><Link key={ user.id } to={ "/admin/users/" + user.id }>View</Link></td>
				<td name="link">{ ( props.currentUser.id !== user.id ) ? <button key={ user.id } onClick={ () => handleDeleteUserClick( user.id ) }><IconDelete /></button> : '' }</td>
			</tr>
		);

		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'admin-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					<table className="users">
						<thead>
							<tr>
								<th name="fullName">Full Name</th>
								<th name="email">Email</th>
								<th name="Phone">Phone</th>
								<th name="Company">Company</th>
								<th name="link">&nbsp;</th>
								<th name="delete">&nbsp;</th>
							</tr>
						</thead>
						<tbody>{ usersList }</tbody>
					</table>
					{ ( meta ) ?
					<Stack spacing={2} sx={{ alignItems: 'center' }}>
						<Pagination count={ meta.last_page } default={ currentPage } onChange={ updatePageNumber } shape="rounded" />
					</Stack> : undefined }
					<div className="buttons-span">
						<div className="submit-buttons">
							<Link className={ classNames( ['button'] ) } to={ "/admin/users/new" }>New User</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<p>{ message }</p>
	);
};

export default AdminUsers;