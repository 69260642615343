import apiClient from './apiClient';

class UploadFilesService {
	upload( file, onUploadProgress, destination = 'files' ) {
		let formData = new FormData();
		formData.append( "file", file );

    return apiClient.post( "/api/v1/" + destination, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
	}

	getFiles() {
		return apiClient.get( "/api/v1/files" );
	}
}

export default new UploadFilesService();