import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import apiClient from '../services/apiClient';

import { Link } from 'react-router-dom';
import { VideoTiles } from './VideoTiles';
import BackButton from './BackButton';

import { ReactComponent as IconTraceProfileLarge } from '../images/icon-trace-profiles-small.svg';
import { ReactComponent as IconAccreditation } from '../images/icon-accreditation.svg';
import { ReactComponent as IconCertification } from '../images/icon-certification.svg';

import { ReactComponent as IconDeliveredSmall } from '../images/icon-delivered-small.svg';
import { ReactComponent as IconDeliveryDateSmall } from '../images/icon-delivery-date-small.svg';
import { ReactComponent as IconOutForDeliverySmall } from '../images/icon-out-for-delivery-small.svg';


const Order = ( props ) => {
	// const [order, setOrder] = useState( [] );
	const [hasError, setHasError] = useState( false );
	const [message, setMessage] = useState( 'You are not logged in' );
	const [isLoaded, setIsLoaded] = useState( false );
	const [orderStatus, setOrderStatus] = useState();
	const videos = [
		{ "url": "", "placeholderUrl": window.origin + "/assets/images/grower.jpg" }
	];

	useEffect( () => {
		if( props.loggedIn && props.id ) {
			// apiClient.get( '/api/v1/orders/' + props.id )
			// .then( response => console.log( response ) );

			apiClient.get( '/api/v1/orders/' + props.id )
				.then( response => {
					const order = response.data.data;
					setOrderStatus(
						<ul className="status">
							<li className={ classNames( { 'is-active' : order.statusLabel !== "None" } ) }>
								<p className="label">Proposed Delivery<br />{ ( order.proposedDeliveryDate ) ? new Date( order.proposedDeliveryDate * 1000 ).toDateString() : '' }</p>
								<div className="circle"></div>
								<IconDeliveredSmall />
							</li>
							<li className={ classNames( 'line', { 'is-active': order.statusLabel !== "None" && order.statusLabel !== "Processed Delivery" } ) }></li>
							<li className={ classNames( { 'is-active': order.statusLabel !== "None" && order.statusLabel !== "Processed Delivery" } ) }>
								<p className="label">Processing<br />{ ( order.outForDeliveryDate ) ? new Date( order.outForDeliveryDate * 1000 ).toDateString() : '' }</p>
								<div className="circle"></div>
								<IconDeliveryDateSmall />
							</li>
							<li className={ classNames( 'line', { 'is-active' : order.statusLabel === 'Delivered' } ) }></li>
							<li className={ classNames( { 'is-active' : order.statusLabel === 'Delivered' } ) }>
								<p className="label">Delivered<br />{ ( order.delivered_date ) ? new Date( order.delivered_date * 1000 ).toDateString() : '' }</p>
								<div className="circle"></div>
								<IconOutForDeliverySmall />
							</li>
						</ul>
					);

					setIsLoaded( true );
				})
				.catch( ( error ) => {
					setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}
	}, [ props.loggedIn, props.id ]);

	if( props.loggedIn && hasError === false ) {
		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'orders-layout' ) }>
				<BackButton />
				<div className="layout-tiles orders-layout-tiles">
					<ul className="list" data-items="4">
						<li className="button trace-profile-listing">
							<Link to="trace-profile">
								<IconTraceProfileLarge />
								<h3>Trace Profile Listing</h3>
							</Link>
						</li>
						<li className="content">
							{ orderStatus }
						</li>
						<li className="button trace-profile-accreditation">
							<Link to="quality-data">
								<IconAccreditation />
								<h3>Quality Data</h3>
							</Link>
						</li>
						<li className="button trace-profile-certification">
							<Link to="certifications-and-accreditations">
								<IconCertification />
								<h3>Certifications and Accreditations</h3>
							</Link>
						</li>
					</ul>
				</div>
				{ ( videos ) ? <VideoTiles videos={ videos } /> : undefined }
			</div>
		);
	}
	return (
		<p>{ message }</p>
	);
}

export default Order;