import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form"; // Form Validation
import { toast } from 'react-toastify';
import classNames from 'classnames';

import apiClient from '../services/apiClient';

import handleErrorMessage from '../services/handleErrorMessage';

const User = ( props ) => {
	const [isNew, setIsNew] = useState( true );
	const [companies, setCompanies] = useState( [] );
	const [hasError, setHasError] = useState( false );
	const [isLoaded, setIsLoaded] = useState( false );
	const [message, setMessage] = useState( 'You are not logged in' );
	const navigate = useNavigate();

	const { register, handleSubmit, reset, formState: { errors } } = useForm();

	const trapSpacesForRequiredFields = ( value ) => !!value.trim();

	useEffect( ( ) => {
		if( props.loggedIn ) {
			if( props.id ) {
				// Load both User & List of Companies
				Promise.all( [ getUser( props.id ), getCompanies() ] )
				.then( response => {
					if( response.length === 2 ) {
						const data = response[0].data.data;
						
						reset( {
							"firstName": data.firstName,
							"lastName": data.lastName,
							"email": data.email,
							"phone": data.phone,
							"password": "",
							"companyId": data.companyId,
							"id": data.id
						} );
						setIsNew( false );
						setCompanies( response[1].data.data );
						setIsLoaded( true );
					}
				} )
				.catch( handleErrorMessage );
			}
			else {
				// New User
				reset( {
					"id": 0
				} );
				
				Promise.all( [ getCompanies() ] )
				.then( response => {
					setCompanies( response[0].data.data );
					setIsLoaded( true );
				} )
				.catch( handleErrorMessage );
			}
		}
	}, [ props.loggedIn, props.id, reset ]);

	const getUser = ( id ) => {
		return apiClient.get( '/api/v1/users/' + id );
	}

	const getCompanies = () => {
		return apiClient.get( '/api/v1/companies?showAll=1' );
	}

	// const handleFormSubmit = ( event ) => {
	const handleFormSubmit = ( data ) => {
		if( data.id !== 0 ) {
			apiClient( {
				method: 'patch',
				url: '/api/v1/users/' + data.id,
				data: {
					first_name: data.firstName,
					last_name: data.lastName,
					email: data.email,
					company_id: data.companyId,
					phone: data.phone,
					password: data.password
				}
			} )
			.then( response => {					
				if( response.status === 200 && response.data === 1 ) {
					toast.success( 'User updated' );
				}
			})
			.catch( handleErrorMessage );
		}	
		else {
			setIsLoaded( false );
			apiClient( {
				method: 'post',
				url: '/api/v1/users',
				data: {
					name: data.firstName + ' ' + data.lastName,
					first_name: data.firstName,
					last_name: data.lastName,
					email: data.email,
					company_id: data.companyId,
					phone: data.phone,
					password: data.password
				}
			} )
			.then( response => {			
				setIsLoaded( true );	
				if( response.status === 201 ) {
					toast.success( 'User created' );
					if( response.data.id ) {
						navigate( '/admin/users/' + response.data.id );
					}
				}
				// setIsLoaded( true );
			})
			.catch( handleErrorMessage );
		}
	}

	if( props.loggedIn && hasError === false ) {
		let userForm;

		// console.log( errors );
		
		if( isLoaded ) {
			const companiesOptions = companies.map( ( company ) =>
				<option key={ company.id } value={ company.id }>{ company.name }</option>
			);
			const companiesSelect = <select { ...register( "companyId" ) }>
				<option key={ 0 } value={ 0 }>Select a company</option>
				{ companiesOptions }
			</select>;
			userForm = <form autoComplete="off" onSubmit={ handleSubmit( handleFormSubmit ) }>
				<label>First Name *<br /><input type="text" { ...register( "firstName", { required: true } ) } aria-invalid={ errors.firstName ? "true" : "false" }  /></label>
				<label>Last Name *<br /><input type="text" { ...register( "lastName", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.lastName ? "true" : "false" } /></label>
				<label>Email *<br /><input type="email" { ...register( "email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ } ) } aria-invalid={ errors.email ? "true" : "false" } autoComplete="off" /></label>
				<label>Phone *<br /><input type="text" { ...register( "phone", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.phone ? "true" : "false" } /></label>
				<label>Password { ( isNew ) ? '*' : '' }<br /><input type="password" { ...register( "password", { required: isNew } ) } aria-invalid={ errors.password ? "true" : "false" } autoComplete="new-password" /></label>
				<label>Company<br />{ companiesSelect }</label>
				<div className="buttons-span">
					<div className="submit-buttons">
						<Link className={ classNames( ['button', 'cancel'] ) } to={ "/admin/users/" }>Cancel</Link>
						<button name="submit">{ ( isNew ) ? "Create User" : "Submit" }</button>
					</div>
				</div>
			</form>
		}
		
		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'admin-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					{ userForm }
				</div>
			</div>
		);
	}

	return (
		<p>{ message }</p>
	);
};

export default User;