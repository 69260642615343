
const ConnectionError = ( props ) => {
	return <div className="inner-layout login-layout">
		<div className="form-wrapper">
			<h3>Connection Error</h3>
			<p>The server is currently experiencing issues. Please check back shortly</p>
		</div>
	</div>;
}

export default ConnectionError;