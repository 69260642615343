import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import { ReactComponent as IconPlay } from '../images/icon-play-button.svg';
import { ReactComponent as IconClose } from '../images/icon-close.svg';
import { isEmpty } from 'lodash';

export const Video = ( props ) => {
	const { url, placeholderUrl, label } = props;
	const [isLoaded, setIsLoaded] = useState();
	
	let toggleVideoModal = () => {
		setIsLoaded( !isLoaded );
	}

	const image = ( placeholderUrl ) ? <img className="video-placeholder" src={ placeholderUrl } alt="" loading="lazy" /> : undefined;

	return (
		<div className="video-wrapper">
			{ image }
			{ ( label ) ? <label>{ label }</label> : '' }
			{ ( !isEmpty( url ) ) ?
				<button className="play-video" onClick={ toggleVideoModal }>
					<IconPlay />
				</button>
			: '' }
			
			{ ( isLoaded ) ? 
				<div className="video-modal-outer">
					<button className="close-video-modal" onClick={ toggleVideoModal }>
						<IconClose />
					</button>
					<div className="video-modal-inner">
						<ReactPlayer 
							className="react-player"
							url={ url }
							width="100%"
							height="100%"
							controls={ true }
						/>
					</div>
				</div>
			: '' }
		</div>
	);	
}