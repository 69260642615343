import { useNavigate } from 'react-router-dom';

const BackButton = ( props ) => {
	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

	return (
		<div className="back-wrapper">
			<button onClick={ goBack }>Back</button>
		</div>
	);
}

export default BackButton;