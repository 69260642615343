import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import apiClient from '../services/apiClient';

import { Link } from 'react-router-dom';
import { VideoTiles } from './VideoTiles';
import BackButton from './BackButton';

import { ReactComponent as IconClose } from '../images/icon-close.svg';
import { ReactComponent as IconAccreditationSmall } from '../images/icon-certification-small.svg';
// import { ReactComponent as IconCertificationSmall } from '../images/icon-accreditation-small.svg'
import { ReactComponent as IconDownload } from '../images/icon-download.svg';
// import { forEach } from 'lodash';


const OrderQualityData = ( props ) => {
	// const [order, setOrder] = useState( [] );
	const [hasError, setHasError] = useState( false );
	const [message, setMessage] = useState( 'You are not logged in' );
	const [isLoaded, setIsLoaded] = useState( false );
	const [qualityItems, setQualityItems] = useState();
	const videos = [
		{ "url": "", "placeholderUrl": window.origin + "/assets/images/grower.jpg" }
	];

	useEffect( () => {
		if( props.loggedIn && props.id ) {
			apiClient.get( '/api/v1/orders/' + props.id, { params: { includeOrderData: true } } )
				.then( response => {
					const order = response.data.data;

					
					
					
					
					// Text Items
					const listItems = [];
					if( order.weightQty ) { 
						listItems.push( { label: "Weight/QTY", value: order.weightQty } );
					}
					if( order.micron ) { 
						listItems.push( { label: "Micron", value: order.micron } );
					}
					if( order.hauteur ) { 
						listItems.push( { label: "Hauteur (mm)", value: order.hauteur } );
					}
					if( order.cvh ) { 
						listItems.push( { label: "CVH (%)", value: order.cvh } );
					}
					if( order.fibresShorterThan30mm ) { 
						listItems.push( { label: "Fibres Shorter than 30mm (%)", value: order.fibresShorterThan30mm } );
					}
					
					const items = ( listItems ) ? 
						listItems.map( ( item, key ) => 
							<tr key={ key + '_t' }>
								<th name="label">{ item.label }</th>
								<td name="value">{ item.value }</td>
							</tr>
						) : [];

					// Downloadable Items
					const files = ( order.qualityDataFiles ) ?
						order.qualityDataFiles.map( ( file ) => 
							<tr key={ file.id }>
								<th name="label">{ file.pivot.label }</th>
								<td name="value"><a key={ file.id } className="download" target="_blank" rel="noreferrer" href={ file.url }><IconDownload />DOWNLOAD</a></td>
							</tr>
						) : [];
					
					setQualityItems( [ ...items, ...files ] );
					
					setIsLoaded( true );
				})
				.catch( ( error ) => {
					setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}
	}, [ props.loggedIn, props.id ]);

	if( props.loggedIn && hasError === false ) {
		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'orders-layout' ) }>
				<BackButton />
				<div className="layout-table orders-layout-table">
					<ul className="quick-links">
						<li>
							<Link to={ "/orders/" + props.id + "/certifications-and-accreditations" }>
								<IconAccreditationSmall />
							</Link>
						</li>
						<li>
							<Link to={ "/orders/" + props.id }>
								<IconClose />
							</Link>
						</li>
					</ul>
					<table className={ classNames( 'files' ) }>
						<tbody>
							{ qualityItems }
						</tbody>
					</table>
				</div>
				{ ( videos ) ? <VideoTiles videos={ videos } /> : undefined }
			</div>
		);
	}
	return (
		<p>{ message }</p>
	);
}

export default OrderQualityData;