import React from 'react';
import apiClient from '../services/apiClient';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import classNames from 'classnames';
import { useForm } from "react-hook-form"; // Form Validation

export const LoginReset = ( props ) => {
	const { register, reset, handleSubmit, watch, formState: { errors } } = useForm( {
		defaultValues: {
			email: window.location.search.substring( 7 ),
			token: props.id
		}
	} );

	const handleFormSubmit = ( data ) => {
		if( data.email.length === 0 || data.token.length === 0 ) {
			toast.error( 'Something went wrong, please try submitting the lost password form again' );
		}
		
		apiClient( {
			method: 'post',
			url: '/api/v1/reset',
			data: {
				'email': data.email,
				'token': data.token,
				'password': data.password,
				'password_confirmation': data.passwordRepeat
			} 
		} )
		.then( response => {
			toast.success( response.data.message );
			reset( { email: '', token: '', password: '', passwordRepeat: '' } );
			props.login();
		})
		.catch( ( error ) => {
			if( error.response.data.message ) {
				toast.error( error.response.data.message );
			}
			// setMessage( { type: error.code, message:  } );
		} );
	}

	return (
		<div className="inner-layout login-layout">
      <div className="form-wrapper">
        <h1>Reset</h1>
				<form onSubmit={ handleSubmit( handleFormSubmit ) }>
					<input type="hidden" { ...register( "email" ) } aria-invalid={ errors.password ? "true" : "false" } />
					<input type="hidden" { ...register( "token" ) } aria-invalid={ errors.password ? "true" : "false" } />
					<label>Password<br />	
						<input type="password" { ...register( "password", { required: true } ) } aria-invalid={ errors.password ? "true" : "false" } autoComplete="new-password" />
					</label>
					<label>Password Repeat<br />	
						<input type="password" { ...register( "passwordRepeat", { 
							required: true,
							validate: ( ps ) => {
								if( watch( 'password' ) !== ps ) {
									return "Your passwords do no match";
								}
							}	
						} ) } aria-invalid={ errors.passwordRepeat ? "true" : "false" } autoComplete="new-password-repeat" />
					</label>
					<button type="submit">Reset</button>
				</form>
				<Link to="/">Back to Login</Link>
      </div>
    </div>
	);
}

export default LoginReset;