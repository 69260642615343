import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form"; // Form Validation
import { toast } from 'react-toastify';
import classNames from 'classnames';
import ReactDatePicker from 'react-datepicker'

import apiClient from '../services/apiClient';
import UploadFiles from '../services/UploadFiles';
import UploadedFilesList from '../services/UploadedFilesList';
// import { ReactComponent as IconDelete } from '../images/icon-delete.svg';
// import { ReactComponent as IconAdd } from '../images/icon-plus.svg';

import handleErrorMessage from '../services/handleErrorMessage';

// import { ReactComponent as IconDelete } from '../images/icon-delete.svg';

const orderStatus = [
	{ key: 'n', label: 'None' },
	{ key: 'p', label: 'Processed Delivery' }, 
	{ key: 'o', label: 'Processing' }, 
	{ key: 'd', label: 'Delivered' } 
];

const AdminOrder = ( props ) => {
	// const [order, setOrder] = useState( [] );
	// const [companies, setCompanies] = useState( [] );
	const [isNew, setIsNew] = useState( true );
	const [isLoaded, setIsLoaded] = useState( false );
	const [growers, setGrowers] = useState( [] );
	const [retailers, setRetailers] = useState( [] );
	const [spinners, setSpinners] = useState( [] );
	const [certifications, setCertifications] = useState( [] );
	const [qualityData, setQualityData] = useState( [] );
	const [qualityDataText, setQualityDataText] = useState( [] );
	const [traceCert, setTraceCert] = useState( [] );
	const [transCert, setTransCert] = useState( [] );
	const [certOfMulesing, setCertOfMulesing] = useState( [] );
	const [orderId, setOrderId] = useState( ( props.id ) ? props.id : null );

	const { control, register, handleSubmit, reset, formState: { errors } } = useForm();
	const navigate = useNavigate();

	const trapSpacesForRequiredFields = ( value ) => !!value.trim();

	useEffect( ( ) => {
		if( props.loggedIn ) {
			if( orderId ) {
				// Load both Order and Lists of Retails, Spinners and Growers
				Promise.all( [ getOrder( orderId ), getGrowers(), getCompanies() ] )
				.then( response => {
					const currentOrder = response[0].data.data;
					const traceProfiles = currentOrder.traceProfiles?.map( ( grower ) => grower.id.toString() );
					const retailers = ( currentOrder.retailers.length ) ? currentOrder.retailers[0].id : '';
					const spinners = ( currentOrder.spinners.length ) ? currentOrder.spinners[0].id : '';

					// Set form defaults
					reset( {
						'batchId': currentOrder.batchId,
						'traceProfiles': traceProfiles,
						'id': currentOrder.id,
						'orderNumber': currentOrder.orderNumber, 
						'deliveredDate': ( currentOrder.deliveredDate ) ? new Date( currentOrder.deliveredDate * 1000 ) : '',
						'outForDeliveryDate': ( currentOrder.outForDeliveryDate ) ? new Date( currentOrder.outForDeliveryDate * 1000 ) : '', // ,
						'proposedDeliveryDate': ( currentOrder.proposedDeliveryDate ) ? new Date( currentOrder.proposedDeliveryDate * 1000 ) : '',
						'retailers': retailers, 
						'spinners': spinners, 
						'status': currentOrder.status, 
						'type': currentOrder.type, 
						'userType': currentOrder.userType, 
						'weightQty': currentOrder.weightQty,
						'micron': currentOrder.micron,
						'hauteur': currentOrder.hauteur,
						'cvh': currentOrder.cvh,
						'fibresShorterThan30mm': currentOrder.fibresShorterThan30mm
					} );
					// Existing
					setIsNew( false );
					// Quality Data Text
					setQualityDataText( ( currentOrder.qualityDataText ) ? currentOrder.qualityDataText : [] );
					// Growers list
					setGrowers( response[1].data.data );
					// Split into spinners and retailers
					setRetailers( response[2].data.data.filter( company => company.type === "retailer" ) );
					setSpinners( response[2].data.data.filter( company => company.type === "spinner" ) );
					// Files
					setCertifications( currentOrder.certificationAccreditationFiles );
					setQualityData( currentOrder.qualityDataFiles );
					// Predefined Certs
					setTraceCert( ( currentOrder.traceCert ) ? currentOrder.traceCert : [] );
					setTransCert( ( currentOrder.transCert ) ? currentOrder.transCert : [] );
					setCertOfMulesing( ( currentOrder.certOfMulesing ) ? currentOrder.certOfMulesing : [] );

					setIsLoaded( true );
				} )
				.catch( handleErrorMessage );
			}
			else {
				// New Order
				Promise.all( [ getGrowers(), getCompanies() ] )
				.then( response => {
					reset( {
						id: 0
					} );
					// Growers list
					setGrowers( response[0].data.data );
					// Split into spinners and retailers
					setRetailers( response[1].data.data.filter( company => company.type === "retailer" ) );
					setSpinners( response[1].data.data.filter( company => company.type === "spinner" ) );					
					setIsLoaded( true );
				} )
				.catch( handleErrorMessage );
			}
		}
	}, [ props.loggedIn, orderId ]);

	const getGrowers = () => {
		return apiClient.get( '/api/v1/growers?showAll=1' );
	}

	const getCompanies = () => {
		return apiClient.get( '/api/v1/companies?showAll=1' );
	}

	const getOrder = ( id ) => {
		return apiClient.get( '/api/v1/orders/' + id );
	}

	// const handleInputChange = ( event ) => {
	// 	const target = event.target;
	// 	const name = target.name;
	// 	const value = target.type === 'checkbox' ? target.checked : target.value;
	
	// 	setOrder( { ...order, [name]: value } );
	// }

	// const handleRoleChange = ( event ) => {
	// 	setOrder( { ...order, role: ( event.target.checked ) ? 'admin' : 'order' } );
	// }

	// const handleSelectChange = ( event ) => {
	// 	setOrder( { ...order, companyId: event.target.value } );
	// }

	const newCertificationUpload = ( data ) => {
		setCertifications( [...certifications, data] );
	}
	const newQualityDataUpload = ( data ) => {
		setQualityData( [...qualityData, data] );
	}
	const newTraceCertUpload = ( data ) => {
		setTraceCert( [data] ); // One item only
	}
	const newTransCertUpload = ( data ) => {
		setTransCert( [data] ); // One item only
	}
	const newCertOfMulesingUpload = ( data ) => {
		setCertOfMulesing( [data] ); // One item only
	}

	const updateCertifications = ( newCertifications ) => {
		setCertifications( newCertifications );
	}
	const updateQualityData = ( newQualityData ) => {
		setQualityData( newQualityData );
	}
	const updateTraceCert = ( newTraceCert ) => {
		setTraceCert( newTraceCert );
	}
	const updateTransCert = ( newTransCert ) => {
		setTransCert( newTransCert );
	}
	const updateCertOfMulesing = ( newCertOfMulesing ) => {
		setCertOfMulesing( newCertOfMulesing );
	}

	// const updateKeyLabel = ( targetValue, targetKey, targetType ) => {
	// 	const newList = qualityDataText.map( ( item, key ) => {
	// 		if( targetKey === key ) {
	// 			item[targetType] = targetValue;
	// 		}
	// 		return item;
	// 	} );
	// 	setQualityDataText( newList );
	// }

	// const addKeyLabelRow = () => {
	// 	setQualityDataText( [...qualityDataText, { label: '', value: '' }] );
	// }

	// const removeKeyLabelRow = ( targetKey ) => {
	// 	const newList = qualityDataText.filter( ( item, key ) => key !== targetKey );
	// 	setQualityDataText( newList );
	// }
	
	const handleFormSubmit = ( data ) => {
		// Update
		if( data.id !== 0 ) {
			// setIsLoaded( false );
			apiClient( {
				method: 'patch',
				url: '/api/v1/orders/' + data.id,
				data: {
					id: data.id,
					batchId: data.batchId,
					orderNumber: data.orderNumber,
					deliveredDate: ( data.deliveredDate ) ? Math.floor( data.deliveredDate / 1000 ) : null,
					outForDeliveryDate: ( data.outForDeliveryDate ) ? Math.floor( data.outForDeliveryDate / 1000 ) : null,
					proposedDeliveryDate: ( data.proposedDeliveryDate ) ? Math.floor( data.proposedDeliveryDate / 1000 ) : null,
					retailers: [ parseInt( data.retailers ) ],
					spinners: [ parseInt( data.spinners ) ],
					status: data.status,
					traceProfiles: ( data.traceProfiles ) ? data.traceProfiles.map( ( i ) => parseInt( i ) ) : [],
					type: data.type,
					weightQty: data.weightQty,
					qualityDataFiles: qualityData,
					certifications: certifications,
					traceCert: ( traceCert.length > 0 ) ? parseInt( traceCert[0].id ) : 0,
					transCert: ( transCert.length > 0 ) ? parseInt( transCert[0].id ) : 0,
					certOfMulesing: ( certOfMulesing.length > 0 ) ? parseInt( certOfMulesing[0].id ) : 0,
					qualityDataText: qualityDataText,
					micron: ( data.micron ) ? data.micron : '',
					hauteur: ( data.hauteur ) ? data.hauteur : '',
					cvh: ( data.cvh ) ? data.cvh : '',
					fibresShorterThan30mm: ( data.fibresShorterThan30mm ) ? data.fibresShorterThan30mm : ''
				}
			} )
			.then( response => {
				// User message
				if( response.status === 200 && response.data === 1 ) {
					toast.success( "Order successfully update" );
					
					// Redirect to show all orders
					navigate( '/admin/orders' );
				}

				
			})
			.catch( handleErrorMessage );
		}		
		else {
			// setIsLoaded( false );
			// Create
			apiClient( {
				method: 'post',
				url: '/api/v1/orders',
				data: {
					batchId: data.batchId,
					orderNumber: data.orderNumber,
					deliveredDate: ( data.deliveredDate ) ? Math.floor( data.deliveredDate / 1000 ) : null,
					outForDeliveryDate: ( data.outForDeliveryDate ) ? Math.floor( data.outForDeliveryDate / 1000 ) : null,
					proposedDeliveryDate: ( data.proposedDeliveryDate ) ? Math.floor( data.proposedDeliveryDate / 1000 ) : null,
					retailers: [ parseInt( data.retailers ) ],
					spinners: [ parseInt( data.spinners ) ],
					status: data.status,
					traceProfiles: ( data.traceProfiles ) ? data.traceProfiles.map( ( i ) => parseInt( i ) ) : [],
					type: data.type,
					weightQty: data.weightQty,
					qualityDataFiles: qualityData,
					certifications: certifications,
					traceCert: ( traceCert.length > 0 ) ? parseInt( traceCert[0].id ) : 0,
					transCert: ( transCert.length > 0 ) ? parseInt( transCert[0].id ) : 0,
					certOfMulesing: ( certOfMulesing.length > 0 ) ? parseInt( certOfMulesing[0].id ) : 0,
					qualityDataText: qualityDataText,
					micron: ( data.micron ) ? data.micron : '',
					hauteur: ( data.hauteur ) ? data.hauteur : '',
					cvh: ( data.cvh ) ? data.cvh : '',
					fibresShorterThan30mm: ( data.fibresShorterThan30mm ) ? data.fibresShorterThan30mm : ''
				}
			} )
			.then( response => {					
				// setIsLoaded( true );	
				if( response.status === 201 ) {
					// User message
					toast.success( "Order successfully created" );
					if( response.data.id ) {
						setOrderId( response.data.id );
						
						// Redirect to newly created order
						// navigate( '/admin/orders/' + response.data.id );
						
						// Redirect to show all orders
						navigate( '/admin/orders' );
					}
				}
			})
			.catch( handleErrorMessage );
		}
	}

	if( props.loggedIn ) {
		let orderForm;	
		
		const orderStatusSelect = <select { ...register( "status", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.status ? "true" : "false" }>
			{ orderStatus.map( ( option ) => (
				<option key={ option.key } value={ option.key }>{ option.label }</option>
			) ) }
		</select>;
		
		const growersCheckbox = growers.map( ( grower ) =>
			<label key={ grower.id }>
				<input type="checkbox" key={ grower.id } { ...register( "traceProfiles" ) } value={  grower.id } />{ grower.name } 
			</label>
		);
		
		const retailerSelect = ( retailers ) ? <select { ...register( "retailers" ) }  aria-invalid={ errors.retailers ? "true" : "false" }>
			<option key={ 0 } value="">Select a retailer</option>
			{ retailers.map( ( retailer ) =>
				<option key={ retailer.id } value={ retailer.id }>{ retailer.name }</option>
			) }
		</select> : '';

		const spinnerSelect = ( spinners ) ? <select { ...register( "spinners" ) }  aria-invalid={ errors.spinners ? "true" : "false" }>
		<option key={ 0 } value="">Select a spinner</option>
		{ spinners.map( ( spinner ) =>
			<option key={ spinner.id } value={ spinner.id }>{ spinner.name }</option>
		) }
		</select> : '';

		orderForm = <form onSubmit={ handleSubmit( handleFormSubmit ) }>
			<label>Batch *<br /><input type="text" { ...register( "batchId", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.batchId ? "true" : "false" } /></label>
			<label>Order Number *<br /><input type="text" { ...register( "orderNumber", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.orderNumber ? "true" : "false" } /></label>
			<label>
				Out For Delivery Date<br />
				<Controller
					control={control}
					name="outForDeliveryDate"
					render={({ field: { onChange, onBlur, value, ref } }) => (
						<ReactDatePicker onChange={onChange} onBlur={onBlur} selected={value} showTimeSelect dateFormat="yyyy-MM-dd HH:mm:ss" />
					)}
				/>
			</label>
			<label>
				Proposed Delivery Date<br />
				<Controller
					control={control}
					name="proposedDeliveryDate"
					render={({ field: { onChange, onBlur, value, ref } }) => (
						<ReactDatePicker onChange={onChange} onBlur={onBlur} selected={value} showTimeSelect dateFormat="yyyy-MM-dd HH:mm:ss" />
					)}
				/>
			</label>
			<label>
				Delivered Date<br />
				<Controller
					control={control}
					name="deliveredDate"
					render={({ field: { onChange, onBlur, value, ref } }) => (
						<ReactDatePicker onChange={onChange} onBlur={onBlur} selected={value} showTimeSelect dateFormat="yyyy-MM-dd HH:mm:ss" />
					)}
				/>
			</label>
			<label>Retailer<br /><div className="select-wrapper">{ retailerSelect }</div></label>
			<label>Spinner<br /><div className="select-wrapper">{ spinnerSelect }</div></label>
			<label>Status<br /><div className="select-wrapper">{ orderStatusSelect }</div></label>
			<label>Growers<br /><div className="checkbox-wrapper">{ growersCheckbox }</div></label>
			<label>Type *<br /><input type="text" { ...register( "type", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.type ? "true" : "false" } /></label>
			<label>Weight/Qty *<br /><input type="text" { ...register( "weightQty", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.weightQty ? "true" : "false" } /></label>
			<label>Micron<br /><input type="text" { ...register( "micron" ) } /></label>
			<label>Hauteur (mm)<br /><input type="text" { ...register( "hauteur" ) } /></label>
			<label>CVH (%)<br /><input type="text" { ...register( "cvh" ) } /></label>
			<label>Fibres Shorter than 30mm (%)<br /><input type="text" { ...register( "fibresShorterThan30mm" ) } /></label>

			<div className="inline-file-group">
				<label>Trace Certificate<br />
					<div className="inline-file">
						<UploadedFilesList files={ traceCert } handler={ updateTraceCert } showInput={ false } />
						{ ( traceCert.length === 0 ) ? <UploadFiles label="Add File" fileUploaded={ newTraceCertUpload } /> : undefined }
					</div>
				</label>
				<label>Transaction Certificate<br />
					<div className="inline-file">
						<UploadedFilesList files={ transCert } handler={ updateTransCert } showInput={ false } />
						{ ( transCert.length === 0 ) ? <UploadFiles label="Add File" fileUploaded={ newTransCertUpload } /> : undefined }
					</div>
				</label>
				<label>Certicate of Mulesing Status<br />
					<div className="inline-file">
						<UploadedFilesList files={ certOfMulesing } handler={ updateCertOfMulesing } showInput={ false } />
						{ ( certOfMulesing.length === 0 ) ? <UploadFiles label="Add File" fileUploaded={ newCertOfMulesingUpload } /> : undefined }
					</div>
				</label>
			</div>
			<div className="files-list">
				<div className="certifications-list">
					<h3>Other Certification and Accreditations</h3>
					<UploadedFilesList files={ certifications } handler={ updateCertifications } showInput={ true } />
					<UploadFiles label="Add File" fileUploaded={ newCertificationUpload } />
				</div>
				{/* <div>
					<h3>Quality Data</h3>
					<table className="key-value-list">
						<thead><tr><th>Label</th><th>Description</th><th className='remove'></th></tr></thead>
						<tbody>
							{ ( qualityDataText ) ? qualityDataText.map( ( item, key ) => 
								<tr key={ key }>
									<td><input type="text" onChange={ ( e ) => updateKeyLabel( e.target.value, key, 'label' ) } value={ item.label } /></td>
									<td><input type="text" onChange={ ( e ) => updateKeyLabel( e.target.value, key, 'value' ) } value={ item.value } /></td>
									<td><IconDelete onClick={ () => removeKeyLabelRow( key ) } /></td>
								</tr>
							) : undefined }
						</tbody>
					</table>
					<label className="button key-value-list-button" onClick={ addKeyLabelRow }><IconAdd /> Add Row</label>
				</div> */}
				<div className="quality-data-list">
					<h3>Quality Data</h3>
					<UploadedFilesList files={ qualityData } handler={ updateQualityData } showInput={ true } />
					<UploadFiles label="Add File" fileUploaded={ newQualityDataUpload } />
				</div>
			</div>
			<div className="buttons-span">
				<div className="submit-buttons">
					<Link className={ classNames( ['button', 'cancel'] ) } to={ "/admin/orders/" }>Cancel</Link>
					<button name="submit">{ ( isNew ) ? "Publish" : "Update" }</button>
				</div>
			</div>
		</form>

		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'admin-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					{ orderForm }
				</div>
			</div>
		);
	}

	return (
		<p>You are not logged in</p>
	);
};

export default AdminOrder;