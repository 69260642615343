import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import apiClient from '../services/apiClient';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { Link } from 'react-router-dom';
import { ReactComponent as IconDelete } from '../images/icon-delete.svg';

const Orders = ( props ) => {
	const [orders, setOrders] = useState( [] );
	const [hasError, setHasError] = useState( false );
	const [message, setMessage] = useState( 'You are not logged in' );
	const [isLoaded, setIsLoaded] = useState( false );
	const [meta, setMeta] = useState( undefined );
	const [currentPage, setCurrentPage] = useState( 1 );

	useEffect( () => {
		fetchOrders();
	}, [ props.loggedIn, currentPage ]);

	const fetchOrders = () => {
		setIsLoaded( false );
		if( props.loggedIn ) {
			apiClient.get( '/api/v1/orders?page=' + currentPage )
				.then( response => {
					setOrders( response.data.data );
					setMeta( response.data.meta );
					setIsLoaded( true );
				})
				.catch( ( error ) => {
					console.log( error );
					setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}
	}

	const updatePageNumber = ( e, newPageNumber ) => {
		setCurrentPage( newPageNumber );
	}

	const handleDeleteOrderClick = ( id ) => {
		if( id ) {
			apiClient( {
				method: 'delete',
				url: '/api/v1/orders/' + id,
				data: {}
			} )
			.then( response => {					
				if( response.status === 200 ) {
					if( response.data === 1 ) {
						toast.success( "Order has been deleted" );
						fetchOrders();
					}
					else {
						toast.error( "Order has failed to be deleted" );
					}
				}
				// setIsLoaded( true );
			})
			.catch( ( error ) => {
				setMessage( error.response.status + ': ' + error.response.statusText );
				setHasError( true );
			} );
		}	
	}

	if( props.loggedIn && hasError === false ) {
		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'admin-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					<table className="orders">
						<thead>
							<tr>
								<th name="orderNumber">Order</th>
								<th name="batchId">Batch</th>
								<th name="grower">Grower</th>
								<th name="weight">Weight/Qty</th>
								<th name="status">Status</th>
								<th name="link">&nbsp;</th>
								<th name="delete">&nbsp;</th>
							</tr>
						</thead>
						<tbody>
							{ orders.map( ( order ) => 
								<tr key={ order.id }>
									<td>{ order.orderNumber.toUpperCase() }</td>
									<td>{ order.batchId.toUpperCase() }</td>
									<td>{ ( order.traceProfiles ) ? order.traceProfiles.map( ( t ) => <span key={ t.id }>{ t.name }</span> ) : undefined }</td>
									<td>{ order.weightQty }</td>
									<td>{ order.statusLabel }</td>
									<td name="link"><Link key={ order.id } to={ "/admin/orders/" + order.id }>View</Link></td>
									<td name="delete"><button key={ order.id } onClick={ () => handleDeleteOrderClick( order.id ) }><IconDelete /></button></td>
								</tr>
							) }
						</tbody>
					</table>
					{ ( meta ) ?
						<Stack spacing={2} sx={{ alignItems: 'center' }}>
							<Pagination count={ meta.last_page } default={ currentPage } onChange={ updatePageNumber } shape="rounded" />
						</Stack> : undefined }
					<div className="buttons-span">
						<div className="submit-buttons">
							<Link className={ classNames( ['button'] ) } to={ "/admin/orders/new" }>Add Order</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<p>{ message }</p>
	);
}

export default Orders;