import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import apiClient from '../services/apiClient';

import { Link } from 'react-router-dom';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { ReactComponent as IconDelete } from '../images/icon-delete.svg';

const AdminGrowers = ( props ) => {
	const [growers, setGrowers] = useState( [] );
	const [hasError, setHasError] = useState( false );
	const [isLoaded, setIsLoaded] = useState( false );
	const [meta, setMeta] = useState( undefined );
	const [message, setMessage] = useState( 'You are not logged in' );
	const [currentPage, setCurrentPage] = useState( 1 );

	useEffect( () => {
		fetchGrowers();
	}, [ props.loggedIn, currentPage ]);

	const fetchGrowers = () => {
		if( props.loggedIn ) {
			apiClient.get( '/api/v1/growers?page=' + currentPage )
			.then( response => {
				setGrowers( response.data.data );
				setMeta( response.data.meta );
				setIsLoaded( true );
			})
			.catch( ( error ) => {
				setMessage( error.response.status + ': ' + error.response.statusText );
				setHasError( true );
			} );
		}
	}

	const updatePageNumber = ( e, newPageNumber ) => {
		setCurrentPage( newPageNumber );
	}

	const handleDeleteGrowerClick = ( id ) => {
		if( id ) {
			apiClient( {
				method: 'delete',
				url: '/api/v1/growers/' + id,
				data: {}
			} )
				.then( response => {					
					if( response.status === 200 ) {
						if( response.data === 1 ) {
							toast.success( "Grower has been deleted" );
							fetchGrowers();
						}
						else {
							toast.error( "Grower has failed to be deleted" );
						}
					}
					// setIsLoaded( true );
				})
				.catch( ( error ) => {
					setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}	
	}

	if( props.loggedIn && hasError === false ) {
		const growersList = growers.map( ( grower ) => 
			<tr key={ grower.id }>
				<td>{ grower.name }</td>
				<td>{ grower.farmerEmail }</td>
				<td>{ grower.location }</td>
				<td>{ grower.sheepTotal }</td>
				<td>{ ( grower.content ) ? ( grower.content.length ) ? grower.content.replace(/<[^>]*>/g, '').substr(0, 33) + '...' : '' : '' }</td>
				<td name="link"><Link key={ grower.id } to={ "/admin/growers/" + grower.id }>View</Link></td>
				<td name="delete"><button key={ grower.id } onClick={ () => handleDeleteGrowerClick( grower.id ) }><IconDelete /></button></td>
			</tr>
		);

		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'admin-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					<table className="users">
						<thead>
							<tr>
								<th name="name">Grower Name</th>
								<th name="farmer-email">Farmer Email</th>
								<th name="location">Location</th>
								<th name="size">Size</th>
								<th name="overview">Overview</th>
								<th name="link">&nbsp;</th>
								<th name="delete">&nbsp;</th>
							</tr>
						</thead>
						<tbody>{ growersList }</tbody>
					</table>
					{ ( meta ) ?
						<Stack spacing={2} sx={{ alignItems: 'center' }}>
							<Pagination count={ meta.last_page } default={ currentPage } onChange={ updatePageNumber } shape="rounded" />
						</Stack> : undefined }
					<form>
						<div className="buttons-span">
							<div className="submit-buttons">
								<Link className={ classNames( ['button'] ) } to={ "/admin/growers/new" }>Add Grower</Link>
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	}

	return (
		<p>{ message }</p>
	);
};

export default AdminGrowers;