import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import apiClient from '../services/apiClient';
import { ReactComponent as IconDownload } from '../images/icon-download.svg';
// import UploadFiles from '../services/UploadFiles';

const Files = ( props ) => {
	// const [hasError, setHasError] = useState( false );
	// const [message, setMessage] = useState( 'You are not logged in' );
	const [isLoaded, setIsLoaded] = useState( false );
	const [files, setFiles] = useState( [] );

	useEffect( () => {
		if( props.loggedIn ) {
			// setIsLoaded( true );
			apiClient.get( '/api/v1/users/' + props.id )
			.then( response => {
				setFiles( response.data.data.miscFiles );
				// let filesLoaded = [];
				// if( response.data.data ) {
				// 	response.data.data.forEach( ( order ) => {
				// 		// Merge 
				// 		if( order.certOfMulesing.length ) { order.certOfMulesing[0].pivot = { label: 'Certicate of Mulesing Status' }; } 
				// 		if( order.traceCert.length ) { order.traceCert[0].pivot = { label: 'Trace Certificate' }; }
				// 		if( order.transCert.length ) { order.transCert[0].pivot = { label: 'Transaction Certificate' }; }

				// 		// console.log( order );
				// 		let thisLoaded = [
				// 			...order.certOfMulesing,
				// 			...order.traceCert,
				// 			...order.transCert,
				// 			...order.qualityDataFiles,
				// 			...order.certificationAccreditationFiles
				// 		];
				// 		let thisLoadedID = thisLoaded.map( ( loads ) => {
				// 			// Append order ID for linking
				// 			loads.orderId = order.id;
				// 			loads.orderNumber = order.orderNumber;
				// 			return loads;
				// 		} );
				// 		filesLoaded = [ ...filesLoaded, ...thisLoadedID ];
				// 	} );
				// 	setFiles( filesLoaded );
				// }
				


				setIsLoaded( true );
			})
			.catch( ( error ) => {
			} );
		}
	}, [ props.loggedIn ]);

	if( props.loggedIn ) { //  && hasError === false
		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'admin-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					<table className={ classNames( 'files' ) }>
						<tbody>
							{ ( files ) ? files.map( ( file ) => (
								<tr key={ file.id }>
									<th name="label">{ file.pivot.label }</th>
									<td name="value"><a key={ file.id } className="download" target="_blank" rel="noreferrer" href={ file.url }><IconDownload />DOWNLOAD</a></td>
								</tr>	
							) ) : '' }
						</tbody>
					</table>
				</div>
			</div>
		);
	}
	return '';
	// return (
	// 	<p>{ message }</p>
	// );
}

export default Files;