import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import apiClient from '../services/apiClient';

import { Link } from 'react-router-dom';
import { VideoTiles } from './VideoTiles';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

// import { ReactComponent as IconBale } from '../images/icon-bale.svg';
import { ReactComponent as IconTraceProfiles } from '../images/icon-trace-profiles-large.svg';


const Orders = ( props ) => {
	const [orders, setOrders] = useState( [] );
	const [hasError, setHasError] = useState( false );
	const [message, setMessage] = useState( 'You are not logged in' );
	const [isLoaded, setIsLoaded] = useState( false );
	const [meta, setMeta] = useState( undefined );
	const [currentPage, setCurrentPage] = useState( 1 );
	const videos = [
		{ 
			"url": "https://player.vimeo.com/video/739556277", 
			"placeholderUrl": window.origin + "/assets/images/trace.jpg",
			"label": "The Likeminded"
		},
		{ 
			"url": "https://player.vimeo.com/video/733504503", 
			"placeholderUrl": window.origin + "/assets/images/process.jpg",
			"label": "The Trace Process"
		},
		{ 
			"url": "https://player.vimeo.com/video/767909290", 
			"placeholderUrl": window.origin + "/assets/images/environmental.jpg",
			"label": "Provenance"
		},
		{ 
			"url": "https://player.vimeo.com/video/767909343", 
			"placeholderUrl": window.origin + "/assets/images/tassie.jpg",
			"label": "Tasmania"
		}
	];

	useEffect( () => {
		if( props.loggedIn ) {
			setIsLoaded( false );
			apiClient.get( '/api/v1/orders?page=' + currentPage )
				.then( response => {
					setOrders( response.data.data );
					setMeta( response.data.meta );
					setIsLoaded( true );
				})
				.catch( ( error ) => {
					setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}
	}, [ props.loggedIn, currentPage ]);

	const updatePageNumber = ( e, newPageNumber ) => {
		setCurrentPage( newPageNumber );
	}

	if( props.loggedIn && hasError === false ) {
		const spinnerLabel = ( orders.length > 0 ) ? ( ( orders[0].userType === "Retailer" ) ? "Spinner" : "Retailer" ) : '';

		const orderItems = orders.map( ( order ) => 
			<tr key={ order.id }>
				<td>{ order.orderNumber }</td>
				<td>{ order.type }</td>
				<td>{ ( order.userType === "Retailer" ) ? ( ( order.spinners ) ? order.spinners.map( ( s ) => s.name ) : '' ) : ( ( order.retailers ) ? order.retailers.map( ( r ) => r.name ) : '' ) }</td>
				<td>{ order.statusLabel }</td>
				<td name="link"><Link key={ order.id } to={ "/orders/" + order.id }>View</Link></td>
			</tr>
		);

		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'dashboard-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					<ul className="quick-links">
					</ul>
					<table className="orders">
						<thead>
							<tr>
								<th name="orderNumber">Order</th>
								<th name="type">Type</th>
								<th name="spinner">{ spinnerLabel }</th>
								<th name="status">Status</th>
								<th name="link"></th>
							</tr>
						</thead>
						<tbody>{ orderItems }</tbody>
					</table>
					{ ( meta ) ?
						<Stack spacing={2} sx={{ alignItems: 'center' }}>
							<Pagination count={ meta.last_page } default={ currentPage } onChange={ updatePageNumber } shape="rounded" />
						</Stack> : undefined }
				</div>
				<div className="layout-tiles dashboard-layout-trace-profiles">
					<ul className="list" data-items="1">
						<li className="button">
							<Link to="/growers">
								<IconTraceProfiles />
								<h3>Trace Profiles</h3>
							</Link>
						</li>
					</ul>
				</div>
				{ ( videos ) ? <VideoTiles videos={ videos } /> : undefined }
			</div>
		);
	}
	return (
		<p>{ message }</p>
	);
}

export default Orders;