import React from 'react';

import { Video } from './Video';

export const VideoTiles = ( props ) => {
	const { videos } = props;
	const videosList = [];
	if( videos ) {
		videos.forEach( ( video, i ) => { 
			const url = ( video?.url ) ? video.url : ( ( video.videoUrl ) ? video.videoUrl : null );
			const placeholderUrl = ( video?.placeholderUrl ) ? video?.placeholderUrl  : ( ( video.imageUrl ) ? video.imageUrl : null );
			const label = ( video?.label ) ? video?.label  : ( ( video.label ) ? video.label : null );

			// const { url, placeholderUrl } = video;
			videosList.push( <li key={ i } className="video is-visible"><Video url={ url } placeholderUrl={ placeholderUrl } label={ label } /></li> );
		} );
	}

	return (
		<div className="layout-tiles orders-layout-tiles">
			<ul className="list" data-items={ videosList.length }>
				{ ( videosList ) ? videosList : <li>Empty</li> }
			</ul>
		</div>
	)
}