import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import apiClient from '../services/apiClient';

import { Link, useNavigate } from 'react-router-dom';
import { VideoTiles } from './VideoTiles';

import { ReactComponent as IconBale } from '../images/icon-bale.svg';
import { ReactComponent as IconTraceProfiles } from '../images/icon-trace-profiles-large.svg';


const Dashboard = ( props ) => {
	const [orders, setOrders] = useState( [] );
	// const [spinnerRetailer, setSpinnerRetailer] = useState();
	const [hasError, setHasError] = useState( false );
	const [message, setMessage] = useState( 'You are not logged in' );
	const [isLoaded, setIsLoaded] = useState( false );
	const navigate = useNavigate();
	const videos = [
		{ "url": "https://player.vimeo.com/video/739556277", "placeholderUrl": window.origin + "/assets/images/trace.jpg" }
	];

	useEffect( () => {
		if( props.loggedIn ) {
			apiClient.get( '/api/v1/orders' )
				.then( response => {
					setOrders( response.data.data );
					setIsLoaded( true );
				})
				.catch( ( error ) => {
					setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}
	}, [ props.loggedIn ]);

	if( props.loggedIn && hasError === false ) {
		const spinnerLabel = ( orders.length > 0 ) ? ( ( orders[0].userType === "Retailer" ) ? "Spinner" : "Retailer" ) : '';

		const orderItems = orders.map( ( order, i ) => {
			// <td>{ ( order.retailers ) ? order.retailers.map( ( s ) => s.name ) : '' }</td>
			// console.log( i );
			if( i >= 5 ) {
				return null;
			}
			return (
				<tr key={ order.id }>
					<td>{ order.orderNumber }</td>
					<td>{ order.type }</td>
					<td>{ ( order.userType === "Retailer" ) ? ( ( order.spinners ) ? order.spinners.map( ( s ) => s.name ) : '' ) : ( ( order.retailers ) ? order.retailers.map( ( r ) => r.name ) : '' ) }</td>
					<td>{ order.statusLabel }</td>
					<td name="link"><Link key={ order.id } to={ "/orders/" + order.id }>View</Link></td>
				</tr>
			);
		} );

		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'dashboard-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					<ul className="quick-links">
						<li><Link to="/orders"><IconBale /></Link></li>			
					</ul>
					<table className="orders">
						<thead>
							<tr>
								<th name="orderNumber">Order</th>
								<th name="type">Type</th>
								<th name="spinner">{ spinnerLabel }</th>
								<th name="status">Status</th>
								<th name="link"></th>
							</tr>
						</thead>
						<tbody>{ orderItems }</tbody>
					</table>
					<Link to="/orders" className="orders-full-listing">See Full Listing</Link>
				</div>
				<div className="layout-tiles dashboard-layout-trace-profiles">
					<ul className="list" data-items="1">
						<li className="button">
							<Link to="/growers">
								<IconTraceProfiles />
								<h3>Trace Profiles</h3>
							</Link>
						</li>
					</ul>
				</div>
				{ ( videos ) ? <VideoTiles videos={ videos } /> : undefined }
			</div>
		);
	}
	return (
		<p>{ message }</p>
	);
}

export default Dashboard;