import React, { Component } from "react";
import apiClient from '../services/apiClient';

export default class UploadedFilesList extends Component {
	constructor( props ) {
		super( props );
	
		this.state = {
			filesList: props.files,
			showInput: ( props.showInput ) ? props.showInput : false
		};
	}
	
	componentDidUpdate( prevProps ) {
		if( this.props.files !== prevProps.files ) {
			this.setState( { filesList: this.props.files } );
		}
	}

	deleteListItem( id ) {
		const newFilesList = this.state.filesList.filter( ( file ) => id !== file.id );
		// Return updated list
		this.props.handler( newFilesList );
		// Trigger server to delete file
		apiClient( {
			method: 'delete',
			url: '/api/v1/files/' + id,
			data: {}
		} )
		.then( response => {
			// console.log( response );
		})
		.catch( ( error ) => {
			console.log( error );
			// setMessage( error.response.status + ': ' + error.response.statusText );
			// setHasError( true );
		} );
	}

	updateListItemLabel( id, newLabel ) {
		const newFilesList = this.state.filesList.map( ( file ) => {
			if( file.id === id ) {
				file.pivot = { label: newLabel };
			}
			return file;
		} );
		// Return updated list
		this.props.handler( newFilesList );
	}

	render() {
		const {
			filesList,
			showInput
		} = this.state;
		
		const renderedList = filesList.map( ( file ) => <li key={ file.id }>
			<a className="delete-file" onClick={ ( e ) => { e.preventDefault(); this.deleteListItem( file.id ); } }>Delete File</a>
			{ 
				( file.mime_type && ( file.mime_type === 'image/png' || file.mime_type === "image/jpg" || file.mime_type === "image/jpeg" ) ) 
				? <a href={ file.url } target="_blank"><img className="file-thumbnail" src={ file.url } alt={ file.pivot?.label } /></a> : 
				( file.url ) ? <a className="file-url" href={ file.url } target="_blank" data-mime={ file.mime_type }>{ file.path }</a> : '' 
			} 
			{ ( showInput ) ? <input className="label-input" type="text" placeholder="Label" value={ file.pivot?.label } onChange={ ( e ) => { this.updateListItemLabel( file.id, e.target.value ) } } /> : '' }
		</li> );

		return <ul>{ renderedList }</ul>;
	}
}