import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form"; // Form Validation
import classNames from 'classnames';
import { toast } from 'react-toastify';
import handleErrorMessage from '../services/handleErrorMessage';

import apiClient from '../services/apiClient';
// import UserMessage from "../components/UserMessage";

const AdminCompany = ( props ) => {
	const [company, setCompany] = useState( [] );
	// const [hasError, setHasError] = useState( false );
	const [isLoaded, setIsLoaded] = useState( false );
	const [isNew, setIsNew] = useState( true );
	// const [message, setMessage] = useState( 'You are not logged in' );

	const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
	const navigate = useNavigate();

	const companyList = [
		{ name: "retailer", label: "Retailer" },
		{ name: "spinner", label: "Spinner" },
		{ name: "agent", label: "Agent" },
	];

	const trapSpacesForRequiredFields =  (value) => !!value.trim();
	
	const retailersSelect = <div className="select-wrapper">
		<select { ...register( "type", { required: true } ) } aria-invalid={ errors.type ? "true" : "false" }>
		{ companyList.map( ( listItem, key ) =>
			<option key={ key } value={ listItem.name }>{ listItem.label }</option>
		) }
		</select>
	</div>;

	const handleFormSubmit = ( data ) => {
		if( !isNew ) {
			apiClient( {
				method: 'patch',
				url: '/api/v1/companies/' + data.id,
				data: {
					id: data.id,
					type: data.type,
					name: data.name,
					reports: data.reports
				}
			} )
			.then( response => {					
				if( response.status === 200 && response.data === 1 ) {
					// User message
					toast.success( 'Company updated' );

					// Redirect to all companies
					navigate( '/admin/companies' );
				}
			})
			.catch( handleErrorMessage );
		}	
		else {
			setIsLoaded( false );
			apiClient( {
				method: 'post',
				url: '/api/v1/companies',
				data: {
					type: data.type,
					name: data.name,
					reports: data.reports
				}
			} )
			.then( response => {			
				setIsLoaded( true );	
				if( response.status === 201 ) {
					// User message
					toast.success( 'Company created' );
					if( response.data.id ) {
						// Redirect to new company
						// navigate( '/admin/companies/' + response.data.id );

						// Redirect to all companies
						navigate( '/admin/companies' );
					}
				}
			})
			.catch( handleErrorMessage );
		}
	}

	useEffect( () => {
		if( props.loggedIn ) {
			if( props.id ) {
				apiClient.get( '/api/v1/companies/' + props.id )
				.then( response => {
					const data = response.data.data;
					
					reset( {
						"id": data.id,
						"name": data.name,
						"type": data.type,
						"reports": data.reports
					} );
					setIsNew( false );
					setIsLoaded( true );
				})
				.catch( handleErrorMessage );
			}
			else {
				setIsLoaded( true );
			}
		}
	}, [ props.loggedIn ]);

	if( props.loggedIn ) {
		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'admin-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					<form onSubmit={ handleSubmit( handleFormSubmit ) }>
						<label>Name *<br />
							<input type="text" { ...register( "name", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.name ? "true" : "false" } />
						</label>
						<label>Type *<br />{ retailersSelect }</label>
						<div className="buttons-span">
							<div className="submit-buttons">
								<Link className={ classNames( ['button', 'cancel'] ) } to={ "/admin/companies/" }>Cancel</Link>
								<button name="submit">{ ( !isNew ) ? "Update" : "Create" }</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	}

	// }

	return (
		<p>You are not logged in</p>
	);
};

export default AdminCompany;

