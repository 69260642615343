import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import apiClient from '../services/apiClient';

import { Link } from 'react-router-dom';
import { VideoTiles } from './VideoTiles';
import BackButton from './BackButton';

import { ReactComponent as IconClose } from '../images/icon-close.svg';


const Grower = ( props ) => {
	const [grower, setGrower] = useState();
	const [closeLink, setCloseLink] = useState( "/growers" );
	const [hasError, setHasError] = useState( false );
	const [message, setMessage] = useState( '' );
	const [isLoaded, setIsLoaded] = useState( false );
	const [growerGallery, setGrowerGallery] = useState( [] )
	// [
	// 	{ "url": "", "placeholderUrl": window.origin + "/assets/images/grower.jpg" }
	// ];

	useEffect( ( ) => {
		if( props.loggedIn && props.id ) {
			apiClient.get( '/api/v1/growers/' + props.id, { params: { includeGrowerGalleries: true } } )
			.then( response => {
				setIsLoaded( true );
				setGrower( response.data.data );
				setGrowerGallery( response.data.data.images.map( ( image ) => {
					return {
						"url": "", 
						"placeholderUrl": image.url
					}
				} ) );
			})
			.catch( ( error ) => {
				setMessage( error.response.status + ': ' + error.response.statusText );
				setHasError( true );
				console.error( error.response.status );
			} );
		}
		if( props.closeUrl ) {
			setCloseLink( props.closeUrl );
		}
	}, [ props.loggedIn, props.id, props.closeUrl ]);

	if( props.loggedIn && hasError === false ) {

		// growersList = growers.map( ( grower ) => 
		// 	<li key={ grower.id } className="button trace-profile-listing">
		// 		<Link to={ "/growers/" + grower.id }>
		// 			<IconTraceProfileLarge />
		// 			<h3>{ grower.name }</h3>
		// 		</Link>
		// 	</li>
		// );
	}

	const growerTable = ( grower ) ?
		<table>
			<tbody>
				{ ( grower.owner ) ? <tr><th>Owner</th><td>{ grower.owner }</td></tr> : undefined }
				{ ( grower.size ) ? <tr><th>Area</th><td>{ grower.size }</td></tr> : undefined }
				{ ( grower.location ) ? <tr><th>Location</th><td>{ grower.location }</td></tr> : undefined }
				{ ( grower.sheepTotal ) ? <tr><th>Total Sheep</th><td>{ grower.sheepTotal }</td></tr> : undefined }
				{ ( grower.accreditation ) ? <tr><td colspan="2">{ grower.accreditation }</td></tr> : undefined }
			</tbody>
		</table> : undefined;

	
	return (
		<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'orders-layout' ) }>
			<BackButton />
			<div className="layout-table orders-layout-table">
				<ul className="quick-links">
					<li>
						<Link to={ closeLink }>
							<IconClose />
						</Link>
					</li>
				</ul>
				{ ( grower )
				? <div className="content grower-content"><div dangerouslySetInnerHTML={ { __html: grower.content } }></div>{ growerTable }</div> : <p>{ message }</p> }
			</div>
			{ ( growerGallery ) ? <VideoTiles videos={ growerGallery } /> : undefined }
		</div>
		// <div className="inner-layout orders-layout">
		// 	<div className="layout-tiles orders-layout-tiles">
		// 		{ ( growersList ) 
		// 		? <ul className="list" data-items="4">
		// 			{ growersList }
		// 		</ul> : { message } }
		// 	</div>
		// 	{ ( videos ) ? <VideoTiles videos={ videos } /> : undefined }
		// </div>
	);
}

export default Grower;