import React from 'react';
import classNames from 'classnames';

const UserSettings = ( props ) => {
	if( props.loggedIn ) {
		
		return (
			<div className={ classNames( { 'is-loading': false }, 'inner-layout', 'admin-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					<h3>User Settings</h3>
					<p>No settings currently</p>
				</div>
			</div>
		);
	}

	return '';
};

export default UserSettings;