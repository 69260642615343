import { toast } from 'react-toastify';

const handleErrorMessage = ( error ) => {
	if( error.response.data.message ) {
		toast.error( error.response.data.message );
	}
	else {
		toast.error( error.response.status + ': ' + error.response.statusText );
	}
}

export default handleErrorMessage;