import React, { useState, useEffect } from 'react';
import apiClient from '../services/apiClient';

import { Link } from 'react-router-dom';
import { VideoTiles } from './VideoTiles';
import BackButton from './BackButton';

import { ReactComponent as IconTraceProfileLarge } from '../images/icon-trace-profiles-small.svg';

const OrderTraceProfiles = ( props ) => {
	const [growers, setGrowers] = useState( [] );
	const [hasError, setHasError] = useState( false );
	const [message, setMessage] = useState( 'You are not logged in' );
	const videos = [
		{ "url": "", "placeholderUrl": window.origin + "/assets/images/grower.jpg" }
	];

	let growersList = null;

	// const { loggedIn } = props;
	useEffect( ( ) => {
		if( props.loggedIn && props.id ) {
		apiClient.get( '/api/v1/orders/' + props.id )
		.then( response => {
			// console.log( response.data.data );
			if( response.data.data?.traceProfiles ) {
				setGrowers( response.data.data?.traceProfiles );
			}
		})
		.catch( ( error ) => {
			setMessage( error.response.status + ': ' + error.response.statusText );
			setHasError( true );
			console.error( error.response.status );
		} );
		}
	}, [ props.loggedIn ]);

	if( props.loggedIn && hasError === false ) {
		growersList = growers.map( ( grower ) => 
			<li key={ grower.id } className="button trace-profile-listing">
				{ ( grower.content !== "" )
					? <Link to={ "/orders/" + props.id + "/trace-profile/" + grower.id }>
						<IconTraceProfileLarge />
						<h3>{ grower.name }</h3>
					</Link>
					: <span>
						<IconTraceProfileLarge />
						<h3>{ grower.name }</h3>
					</span>
				}
			</li>
		);
	}
	return (
		<div className="inner-layout orders-layout">
			<BackButton />
			<div className="layout-tiles orders-layout-tiles">
				{ ( growersList ) 
				? <ul className="list" data-items="4">
					{ growersList }
				</ul> : { message } }
			</div>
			{ ( videos ) ? <VideoTiles videos={ videos } /> : undefined }
		</div>
	);
}

export default OrderTraceProfiles;