import './scss/style.scss';
import "react-datepicker/src/stylesheets/datepicker.scss";
import 'react-toastify/dist/ReactToastify.css';

import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';

import Dashboard from './components/Dashboard';
import Orders from './components/Orders';
import Order from './components/Order';
import Growers from './components/Growers';
import Grower from './components/Grower';
import Files from './components/Files';
import ConnectionError from './components/ConnectionError';
import User from './components/User';
import UserSettings from './components/UserSettings';
import Login from './components/Login';
import LoginForgot from './components/LoginForgot';
import LoginReset from './components/LoginReset';
import AdminUsers from './components/AdminUsers';
import AdminUser from './components/AdminUser';
import AdminOrders from './components/AdminOrders';
import AdminOrder from './components/AdminOrder';
import AdminCompanies from './components/AdminCompanies';
import AdminCompany from './components/AdminCompany';
import AdminGrowers from './components/AdminGrowers';
import { AdminGrowerEdit, AdminGrowerNew } from './components/AdminGrower';
import apiClient from './services/apiClient';
import { ToastContainer } from 'react-toastify';

import { Header, HeaderNoAuth } from './components/Header';
import OrderQualityData from './components/OrderQualityData';
import OrderCertificationsAccreditations from './components/OrderCertificationAccreditation';
import OrderTraceProfiles from './components/OrderTraceProfiles';

export default function App( props ) {
  const [loggedIn, setLoggedIn] = useState(
    sessionStorage.getItem('loggedIn') == 'true' || false
  );
  const [currentUser, setCurrentUser] = useState( false );
  const navigate = useNavigate();

  const login = () => {
    setLoggedIn( true );
    setupUser();
  }

  const setupUser = () => {
    // Fetch user information for role -- needed for menus and not any security reason
    apiClient.get( '/api/v1/status' ).then( response => {
      if( response.data === false ) {
        // Not logged in
        setLoggedIn( false );
        setCurrentUser( false );
        sessionStorage.setItem( 'loggedIn', false );
        
        // Redirect to login
        // navigate( '/' );
      }
      else {
        // Is logged in
        setLoggedIn( true );
        sessionStorage.setItem( 'loggedIn', true );

        // Update current user information
        setCurrentUser( response.data );

        // Navigate to dashboard or orders for admin user
        if( response.data.role === 'admin' ) {
          navigate( '/admin/orders' );
        }
        else {
          navigate( '/dashboard' );
        }
      }
    } )
    .catch( ( error ) => {
      if( error.code === "ERR_NETWORK" ) {
        // Server likely down
        // navigate( '/connection' );
      }
      else {
        // Reset all
        setLoggedIn( false );
        setCurrentUser( false );
        sessionStorage.setItem( 'loggedIn', false );
  
        // Redirect to login
        navigate( '/' );
      }
    } );
  }

  useEffect( ( ) => {
    setupUser();
    // if( loggedIn ) {
    // }
    // else {  
    // }
  }, [] );

  const logout = () => {
    apiClient.post( '/logout' ).then( response => { 
      if( response.status === 204 ) {
        setLoggedIn( false );
        setCurrentUser( false );
        navigate( '/' );
      }
      navigate( '/' );
    } );
  }

  // Log out button
  if( loggedIn === false ) {
    return (
      <div className="App">
        <AppWrapper>
          <HeaderNoAuth />
          <main className="content login">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            <Routes>
              <Route path="/" element={ <Login { ...props } login={ login } /> } />
              <Route path="/forgot" element={ <LoginForgot { ...props } /> } />
              <Route path="/connection" element={ <ConnectionError { ...props } /> } />
              <Route path="/reset">
                <Route path="" element={ <Login { ...props } /> } />
                <Route path=":id" element={ <ResetRoute path="" { ...props } login={ login } /> } />
              </Route>
            </Routes>
          </main>
        </AppWrapper>
      </div>
    );
  }
  else if( loggedIn === true ) {
    // Check we're not on the login page from a refresh
    const role = ( currentUser?.role ) ? currentUser.role : 'user';
    
    return (
      <div className="App">
        <AppWrapper>
          <HeaderRoute user={ currentUser } logout={ logout } role={ role } />
          <main className="content">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            <Routes>
              <Route exact path="/dashboard" element={ <Dashboard { ...props } loggedIn={ loggedIn } /> } />
              <Route path="/growers">
                <Route path="" element={ <Growers { ...props } loggedIn={ loggedIn } /> } />
                <Route path=":id" element={ <GrowerRoute loggedIn={ loggedIn } /> } />
              </Route>
              <Route exact path="/settings" element={ <UserSettings { ...props } loggedIn={ loggedIn } /> } />
              <Route path="/orders">
                <Route path="" element={ <Orders { ...props } loggedIn={ loggedIn } /> } />
                <Route path=":id" element={ <OrderRoute path=""  loggedIn={ loggedIn } /> } />
                <Route path=":id/quality-data" element={ <OrderRoute path="/quality-data" loggedIn={ loggedIn } /> } />
                <Route path=":id/certifications-and-accreditations" element={  <OrderRoute path="/certifications-and-accreditations"  loggedIn={ loggedIn } /> } />
                <Route path=":id/trace-profile" element={ <OrderRoute path="/trace-profiles" loggedIn={ loggedIn } /> } />
                <Route path=":id/trace-profile/:tid" element={ <OrderRoute path="/trace-profile" loggedIn={ loggedIn } /> } />
              </Route>
              <Route path="/files">
                <Route path=":id" element={ <FilesRoute { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } /> 
              </Route>
              <Route path="/users">
                <Route path=":id" element={ <UserRoute { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } />
              </Route>
              <Route path="/admin">
                <Route path="orders">
                  <Route path="" element={ <AdminOrders { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } />
                  <Route path=":id" element={ <AdminOrderRoute { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } />
                  <Route path="new" element={ <AdminOrderRoute { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } />
                </Route>
                <Route path="users">
                  <Route path="" element={ <AdminUsers { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } />
                  <Route path=":id" element={ <AdminUserRoute { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } />
                  <Route path="new" element={ <AdminUserRoute { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } />
                </Route>
                <Route path="companies">
                  <Route path="" element={ <AdminCompanies { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } />
                  <Route path=":id" element={ <AdminCompanyRoute { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } />
                  <Route path="new" element={ <AdminCompanyRoute { ...props } loggedIn={ loggedIn } currentUser={ currentUser } /> } />
                </Route>
                <Route path="growers">
                  <Route path="" element={ <AdminGrowers { ...props } loggedIn={ loggedIn } /> } />
                  <Route path=":id" element={ <AdminGrowerRoute { ...props } loggedIn={ loggedIn } /> } />
                  <Route path="new" element={ <AdminGrowerRoute { ...props } loggedIn={ loggedIn } /> } />
                </Route>
              </Route>
            </Routes>
          </main>
        </AppWrapper>
      </div>
    );
  }
}

export const AppLoadCheck = () => {
  let location = useLocation();
  // console.log( location );
}

export const AppWrapper = ( { children } ) => { // 
  let location = useLocation();
  let classNames = [ 'app-wrapper' ];
  if( location.pathname === '/' || location.pathname.indexOf( '/login' ) !== -1 || location.pathname.indexOf( '/reset' ) !== -1  || location.pathname.indexOf( '/forgot' ) !== -1 ) {
    classNames.push( 'home' );
  }


  return <div className={ ( classNames.join( ' ' ) ) } >{ children }</div>;
}

export const HeaderRoute = ( props ) => {
  return <Header { ...props } logout={ props.logout } role={ props.role } />;
}

export const ResetRoute = ( props ) => {
  let params = useParams();
  return <LoginReset id={ params.id } loggedIn={ props.loggedIn } login={ props.login } />;
}

export const OrderRoute = ( props ) => {
  let params = useParams();

  const path = props.path;

  switch( path ) {
    case '':
      return <Order id={ params.id } loggedIn={ props.loggedIn } />;
    case '/quality-data':
      return <OrderQualityData id={ params.id } loggedIn={ props.loggedIn } />;
    case '/certifications-and-accreditations':
      return <OrderCertificationsAccreditations id={ params.id } loggedIn={ props.loggedIn } />;
    case '/trace-profiles':
      return <OrderTraceProfiles id={ params.id } loggedIn={ props.loggedIn } />;
    case '/trace-profile':
      return <Grower id={ params.tid } closeUrl={ "/orders/" + params.id } loggedIn={ props.loggedIn } />;
    default:
      return <Dashboard { ...props } loggedIn={ props.loggedIn } />
  }
}

export const UserRoute = ( props ) => {
  let params = useParams();
  return <User id={ params.id } loggedIn={ props.loggedIn } />;
}

export const FilesRoute = ( props ) => {
  let params = useParams();
  return <Files id={ params.id } { ...props } />
}

export const AdminUserRoute = ( props ) => {
  let params = useParams();
  return <AdminUser id={ params.id } loggedIn={ props.loggedIn } />;
}

export const AdminOrderRoute = ( props ) => {
  let params = useParams();
  return <AdminOrder id={ params.id } loggedIn={ props.loggedIn } />;
}

export const AdminCompanyRoute = ( props ) => {
  let params = useParams();
  return <AdminCompany id={ params.id } loggedIn={ props.loggedIn } />;
}

export const AdminGrowerRoute = ( props ) => {
  let params = useParams();
  return <AdminGrowerEdit id={ params.id } loggedIn={ props.loggedIn } />;
}

export const GrowerRoute = ( props ) => {
  let params = useParams();
  return <Grower id={ params.id } loggedIn={ props.loggedIn } />
}

// export const OrderQualityDataRoute = () => {
//   return <p>OrderQualityDataRoute</p>;
// }

// export const OrderCertificationsRoute = () => {
//   return <p>OrderQualityDataRoute</p>;
// }

// export const OrderTraceProfileRoute = () => {
//   return <p>OrderQualityDataRoute</p>;
// }