import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import apiClient from '../services/apiClient';

import { Link } from 'react-router-dom';
import { VideoTiles } from './VideoTiles';
import BackButton from './BackButton';

import { ReactComponent as IconClose } from '../images/icon-close.svg';
// import { ReactComponent as IconAccreditationSmall } from '../images/icon-certification-small.svg';
import { ReactComponent as IconCertificationSmall } from '../images/icon-accreditation-small.svg'
import { ReactComponent as IconDownload } from '../images/icon-download.svg';
// import { forEach } from 'lodash';


const OrderCertificationsAccreditations = ( props ) => {
	// const [order, setOrder] = useState( [] );
	const [hasError, setHasError] = useState( false );
	// const [message, setMessage] = useState( 'You are not logged in' );
	const [isLoaded, setIsLoaded] = useState( false );
	const [qualityItems, setQualityItems] = useState();
	const videos = [
		{ "url": "", "placeholderUrl": window.origin + "/assets/images/grower.jpg" }
	];

	useEffect( () => {
		if( props.loggedIn && props.id ) {
			apiClient.get( '/api/v1/orders/' + props.id, { params: { includeOrderData: true } } )
				.then( response => {
					const order = response.data.data;

					// Text Items
					const items = order?.certificationAccreditation.map( ( item ) => 
						<tr key={ item.id }>
							<th name="label">{ item.label }</th>
							<td name="value">{ ( item.fileUrl ) ? <a key={ item.id } className="download" target="_blank" rel="noreferrer" href={ item.fileUrl }><IconDownload />{ item.value }</a> : item.value }</td>
						</tr>
					);

					// Required Certifications
					// Append labels to defaults
					const traceCerts = ( order.traceCert ) ? order?.traceCert.map( ( file ) => { file.pivot = { label: 'Trace Certificate' }; return file } ) : [];
					const transCerts = ( order.transCert ) ? order?.transCert.map( ( file ) => { file.pivot = { label: 'Transaction Certificate' }; return file } ) : [];
					const certOfMulesing = ( order.certOfMulesing ) ? order?.certOfMulesing.map( ( file ) => { file.pivot = { label: 'Certicate of Mulesing Status' }; return file } ) : [];
					// Merge with labelled other files
					const allFiles = [...traceCerts, ...transCerts, ...certOfMulesing, ...order.certificationAccreditationFiles ];
					
					// Downloadable Items
					const files = allFiles.map( ( file ) => 
						<tr key={ file.id }>
							<th name="label">{ file.pivot.label }</th>
							<td name="value"><a key={ file.id } className="download" target="_blank" rel="noreferrer" href={ file.url }><IconDownload />DOWNLOAD</a></td>
						</tr>
					);
					
					
					setQualityItems( [ ...items, ...files ] );
					
					setIsLoaded( true );
				})
				.catch( ( error ) => {
					console.log( error );
					// setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}
	}, [ props.loggedIn, props.id ]);

	if( props.loggedIn && hasError === false ) {
		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'orders-layout' ) }>
				<BackButton />
				<div className="layout-table orders-layout-table">
					<ul className="quick-links">
						<li>
							<Link to={ "/orders/" + props.id + "/quality-data" }>
								<IconCertificationSmall />
							</Link>
						</li>
						<li>
							<Link to={ "/orders/" + props.id }>
								<IconClose />
							</Link>
						</li>
					</ul>
					<table className={ classNames( 'files' ) }>
						<tbody>
							{ qualityItems }
						</tbody>
					</table>
				</div>
				{ ( videos ) ? <VideoTiles videos={ videos } /> : undefined }
			</div>
		);
	}
	return (
		<p>You are not logged in</p>
	);
}

export default OrderCertificationsAccreditations;