import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import apiClient from '../services/apiClient';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { Link } from 'react-router-dom';

import { ReactComponent as IconDelete } from '../images/icon-delete.svg';

const AdminCompanies = ( props ) => {
	const [companies, setCompanies] = useState( [] );
	const [hasError, setHasError] = useState( false );
	const [isLoaded, setIsLoaded] = useState( false );
	const [message, setMessage] = useState( 'You are not logged in' );
	const [meta, setMeta] = useState( undefined );
	const [currentPage, setCurrentPage] = useState( 1 );

	useEffect( () => {
		fetchCompanies();
	}, [ props.loggedIn, currentPage ]);

	const fetchCompanies = () => {
		if( props.loggedIn ) {
			apiClient.get( '/api/v1/companies?page=' + currentPage )
				.then( response => {
					setCompanies( response.data.data );
					setMeta( response.data.meta );
					setIsLoaded( true );
				})
				.catch( ( error ) => {
					setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}
	}

	const handleDeleteCompanyClick = ( id ) => {
		if( id ) {
			apiClient( {
				method: 'delete',
				url: '/api/v1/companies/' + id,
				data: {}
			} )
				.then( response => {					
					if( response.status === 200 ) {
						if( response.data === 1 ) {
							toast.success( "The company has been deleted" );
							fetchCompanies();
						}
						else {
							toast.error( "The company has failed to be deleted" );
						}
					}
					// setIsLoaded( true );
				})
				.catch( ( error ) => {
					setMessage( error.response.status + ': ' + error.response.statusText );
					setHasError( true );
				} );
		}
	}

	if( props.loggedIn && hasError === false ) {
		const companiesList = companies.map( ( company ) => 
			<tr key={ company.id }>
				<td>{ company.name }</td>
				<td>{ company.type[0].toUpperCase() + company.type.substr(1) }</td>
				<td name="link"><Link key={ company.id } to={ "/admin/companies/" + company.id }>View</Link></td>
				<td name="delete"><button key={ company.id } onClick={ () => handleDeleteCompanyClick( company.id ) }><IconDelete /></button></td>
			</tr>
		);

		const updatePageNumber = ( e, newPageNumber ) => {
			setCurrentPage( newPageNumber );
		}

		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'admin-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					<table className="users">
						<thead>
							<tr>
								<th name="name">Name</th>
								<th name="type">Type</th>
								<th name="link">&nbsp;</th>
								<th name="delete">&nbsp;</th>
							</tr>
						</thead>
						<tbody>{ companiesList }</tbody>
					</table>
					{ ( meta ) ?
					<Stack spacing={2} sx={{ alignItems: 'center' }}>
						<Pagination count={ meta.last_page } default={ currentPage } onChange={ updatePageNumber } shape="rounded" />
					</Stack> : undefined }
					<div className="buttons-span">
						<div className="submit-buttons">
							<Link className={ classNames( ['button'] ) } to={ "/admin/companies/new" }>New Company</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<p>{ message }</p>
	);
};

export default AdminCompanies;