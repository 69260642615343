import React, { Component } from "react";
import UploadService from "./UploadFilesService";

export default class UploadFiles extends Component {
	constructor( props ) {
		super( props );
	
		this.state = {
			selectedFiles: [],
			currentFile: '',
			progress: 0,
			message: "",
			fileInfos: [],
			label: props.label ? props.label : 'Upload'
		};
	}

	// componentDidMount() {
	// }

	selectFile( event ) {
		this.setState( {
			selectedFiles: event.target.files,
		}, () => { this.upload() } );
	}

	upload() {
		let currentFile = this.state.selectedFiles[0];

		this.setState( {
			progress: 0,
			currentFile: currentFile,
		} );

		UploadService.upload( currentFile, ( event ) => {
			this.setState( {
				progress: Math.round( ( 100 * event.loaded ) / event.total ),
			} );
		} )
		.then( ( response ) => {
			this.props.fileUploaded( response.data );
		} )
		.catch( ( e ) => {
			this.setState( {
				progress: 0,
				message: "Could not upload the file!",
				currentFile: undefined,
			} );
		} );

		this.setState({
			selectedFiles: undefined,
		});
	}

	render() {
		const {
			currentFile,
			progress,
			message,
			fileInfos,
			label,
		} = this.state;

		return (
			<div className="upload-files">
				<div className="buttons-span">
					<label className="button button-file">
						<input type="file" onChange={ ( e ) => this.selectFile( e ) } />
						{ label }
					</label>
				</div>
				
				{ currentFile && (
					<div className="progress">
						<div
							className="progress-bar progress-bar-info progress-bar-striped"
							role="progressbar"
							aria-valuenow={progress}
							aria-valuemin="0"
							aria-valuemax="100"
							style={{ width: progress + "%" }}
						></div>
					</div>
				) }

				<div className="alert alert-light" role="alert">
					{ message }
				</div>
			</div>
		);
	}
}