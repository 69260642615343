import React, { useState, useContext, createContext } from 'react';
import { Link, NavLink } from 'react-router-dom';

import classNames from 'classnames';

import apiClient from '../services/apiClient';

import { ReactComponent as Logo } from '../images/ame-trace-logo.svg';
import { ReactComponent as IconUser } from '../images/icon-user.svg';
import { ReactComponent as IconNotification } from '../images/icon-notification.svg';
import { toast } from 'react-toastify';
// import { ReactComponent as IconHamburger } from '../images/icon-hamburger.svg';

// Popup contexts
const ModalPopupContext = createContext( { active: '' } );

function useModalPopup() {
  return useContext( ModalPopupContext );
}

function ModalPopupProvider( { children } ) {
  let [modal, setModal] = useState();

	let setActiveModal = ( newModal ) => {
		if( modal === newModal ) {
			// Toggle off
			setModal( '' );
		}
		else {
			setModal( newModal );
		}
	}

	let value = { modal, setActiveModal };

	return <ModalPopupContext.Provider value={value}>{children}</ModalPopupContext.Provider>;
}

let isActiveClassName = 'current-menu-item';

export const EnquiryModal = () => {
	const modalPopup = useModalPopup();
	const [subject, setSubject] = useState( '' );
	const [message, setMessage] = useState( '' );
	const [messageResponse, setMessageResponse] = useState( '' );
	
	const handleEnquiryClick = ( e ) => {
		modalPopup.setActiveModal( 'enquiry' );
	}

	const updateSubject = ( e ) => {
		setSubject( e.target.value );
	}
	const updateMessage = ( e ) => {
		setMessage( e.target.value );
	}

	const isActive = ( modalPopup?.modal === 'enquiry' );

	const submitEnquiry = ( e ) => {
		e.preventDefault();

		if( subject.length && message.length ) {
			apiClient( {
				method: 'post',
				url: '/api/v1/enquiry',
				data: {
					subject: subject,
					message: message
				}
			} )
			.then( response => {			
				setSubject( '' );
				setMessage( '' );
				toast.success( 'Your enquiry has been sent!' );
				modalPopup.setActiveModal( '' );
			})
			.catch( ( error ) => {
				toast.error( 'An error has occurred in sending your enquiry' );
				// setMessage( error.response.status + ': ' + error.response.statusText );
				// setHasError( true );
			} );

			// onClick={ () => { modalPopup.setActiveModal( '' ) } } 
		}
	}

	return (
		<>
			<button className={ classNames( { 'current-menu-item': isActive } ) } onClick={ () => handleEnquiryClick() }>Enquiry</button>
			{ ( isActive ) ? 
			<div className="enquiry-modal modal-popup">
				<form onSubmit={ submitEnquiry }>
					{ ( messageResponse ) ? <p>{ messageResponse }</p> : '' }
					<label><input name="subject" placeholder="Subject" value={ subject } onChange={ updateSubject } required /></label>
					<label><input name="message" placeholder="Message" value={ message } onChange={ updateMessage } required /></label>
					<div className="buttons buttons-span">
						<label onClick={ () => { modalPopup.setActiveModal( '' ) } } className="button cancel">Close</label>
						<button className="button light">Send</button>
					</div>
				</form>
			</div> : '' }
		</>
	);
}

export const UserModal = ( props ) => {
	const modalPopup = useModalPopup();
	
	const handleEnquiryClick = ( e ) => {
		modalPopup.setActiveModal( 'user' );
	}

	const isActive = ( modalPopup?.modal === 'user' );

	return (
		<>
			<button className={ classNames( { 'current-menu-item' : isActive } ) } onClick={ handleEnquiryClick }>
				<IconUser /><span className="label">User</span>
			</button>
			{ ( isActive ) ? 
				<div className="enquiry-modal modal-popup">
					<form>
						<NavLink 
							to={ "/users/" + props.user.id }
							onClick={ handleEnquiryClick }
							className="link"
						>
							Update details
						</NavLink>
						<br />
						<NavLink 
							to={ "/settings/" }
							onClick={ handleEnquiryClick }
							className="link"
						>
							Change cookie and analytics consent
						</NavLink>
					</form>
					<div className="buttons">
						<button onClick={ props.logout }>Logout</button>
					</div>
				</div>
			: '' }
		</>
	);
}

export const AdminModal = () => {
	const modalPopup = useModalPopup();
	
	const handleEnquiryClick = ( e ) => {
		modalPopup.setActiveModal( 'admin' );
	}

	const handleLinkClick = ( ) => {
		modalPopup.setActiveModal( '' );
	}

	const isActive = ( modalPopup?.modal === 'admin' );

	return (
		<>
			<button className={ classNames( { 'current-menu-item' : isActive } ) } onClick={ handleEnquiryClick }>
				Admin
			</button>
			{ ( isActive ) ? 
				<div className={ classNames( ["admin-modal", "modal-popup", "buttons-list" ] ) }>
					<NavLink 
						to="admin/users"
						onClick={ handleLinkClick }
						className="link"
					>
						Users
					</NavLink>
					<NavLink 
						to="admin/companies"
						onClick={ handleLinkClick }
						className="link"
					>
						Companies
					</NavLink>
					<NavLink 
						to="admin/growers"
						onClick={ handleLinkClick }
						className="link"
					>
						Growers
					</NavLink>
				</div>
			: '' }
		</>
	);
}

export const AdminExpanded = () => {
	const modalPopup = useModalPopup();

	const handleLinkClick = ( ) => {
		modalPopup.setActiveModal( '' );
	}

	return (
		<>
			<li>
				<NavLink to="admin/users" onClick={ handleLinkClick }>
					Admin Users
				</NavLink>
			</li>
			<li>
				<NavLink to="admin/companies" onClick={ handleLinkClick }>
					Admin Companies
				</NavLink>
			</li>
			<li>
				<NavLink to="admin/growers" onClick={ handleLinkClick }>
					Admin Growers
				</NavLink>
			</li>
		</>
	);
}

export const NotificationsModal = () => {
	const modalPopup = useModalPopup();
	
	const handleEnquiryClick = ( e ) => {
		modalPopup.setActiveModal( 'notification' );
	}

	const handleSaveClick = ( e ) => {
		e.preventDefault();
		modalPopup.setActiveModal( '' );
	}

	const isActive = ( modalPopup?.modal === 'notification' );

	return (
		<>
			<button className={ classNames( { 'current-menu-item' : isActive } ) } onClick={ handleEnquiryClick }>
				<IconNotification /><span className="label">Notifications</span>
			</button>
			{ ( isActive ) ? 
				<div className="notifications-modal modal-popup">
					<form onSubmit={ handleSaveClick }>
						<label><input name="details" placeholder="Send alerts" /></label>
						<div className="buttons">
							<button className="light">Save</button>
						</div>
					</form>
					<p>Version 1.0.202305160921</p>
				</div>
			: '' }
		</>
	);
}

export const MobileNavigationModal = ( props ) => {
	const modalPopup = useModalPopup();
	
	const handleNavigationClick = ( e ) => {
		modalPopup.setActiveModal( 'navigation' );
	}

	const isActive = ( modalPopup?.modal === 'navigation' );

	return (
		<>
			<button className={ classNames( { 'is-active': isActive }, "mobile-navigation-trigger" ) } onClick={ handleNavigationClick }>
				<span></span>
			</button>
			{ ( isActive ) ?
				<div className="navigation-modal modal-popup">
					<HeaderNavigation className="mobile-navigation navigation" { ...props } role={ props.role } expanded={ true } />
				</div>
			: '' }
		</>
	);
}	



export const HeaderNavigation = ( props ) => {
	const altClassName = ( props?.className ) ? props.className : 'navigation';
	const { role, expanded } = props;
	const modalPopup = useModalPopup();

	const handleLinkClick = ( ) => {
		modalPopup.setActiveModal( '' );
	}

	return (
		<nav className={ altClassName }>
			<ul>
				<li>
					<NavLink 
						to="dashboard"
						onClick={ handleLinkClick }
						className={ ( { isActive } ) => isActive ? isActiveClassName : undefined }
					>
						Dashboard
					</NavLink>
				</li>
				<li>
					<NavLink 
						to={ ( props?.user?.id ) ? "/files/" + props.user.id : "/files" }
						onClick={ handleLinkClick }
						className={ ( { isActive } ) => isActive ? isActiveClassName : undefined }
					>My Files</NavLink>
				</li>
				{/* <li>
					<EnquiryModal />
				</li> */}
				{ ( role === 'admin' ) ? <li>
					<NavLink
						to="admin/orders"
						onClick={ handleLinkClick }
						className={ ( { isActive } ) => isActive ? isActiveClassName : undefined }
					>Orders</NavLink>
				</li> : '' }
				{ ( role === 'admin' && expanded === false ) ?
				<li>
					<AdminModal />
				</li> : '' }
				{ ( role === 'admin' && expanded ) ?
				<AdminExpanded /> : '' }
			</ul>
		</nav>
	);
}

export const UserNavigation = ( props ) => {
	return (
		<nav className="user">
			<ul>
				<li>
					<UserModal { ...props } logout={ props.logout } />
				</li>
				<li>
					<NotificationsModal />
				</li>
				<li className="mobile-navigation">
					<MobileNavigationModal  { ...props } />
				</li>
			</ul>
		</nav>
	);
}

export const Header = ( props ) => {
	return (
		<header className="header">
			<div className="site-logo">
				<Link to="/dashboard">
					<h1>Trace AME</h1>
					<Logo />
				</Link>
			</div>
			<div className="navigation-wrapper">
				<ModalPopupProvider>
					<HeaderNavigation { ...props } role={ props.role } expanded={ false } />
					<UserNavigation { ...props } logout={ props.logout } />
				</ModalPopupProvider>
			</div>
		</header>
	);
}

export const HeaderNoAuth = () => (
	<header className="header no-auth">
		<div className="site-logo">
			<Link to="/">
				<h1>Trace AME</h1>
				<Logo />
			</Link>
		</div>
		<div className="navigation-wrapper"></div>
	</header>
);