// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form"; // Form Validation
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { Editor } from "@tinymce/tinymce-react";

// import { EditorState, convertToRaw, ContentState } from 'draft-js';
// import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';

import apiClient from '../services/apiClient';
import UserMessage from "../components/UserMessage";
import UploadFiles from '../services/UploadFiles';
import UploadedFilesList from '../services/UploadedFilesList';

import handleErrorMessage from '../services/handleErrorMessage';

export const AdminGrowerEdit = ( props ) => {
	const [grower, setGrower] = useState( [] );
	const [hasError, setHasError] = useState( false );
	const [isNew, setIsNew] = useState( true );
	const [isLoaded, setIsLoaded] = useState( false );
	const [message, setMessage] = useState( 'You are not logged in' );
	const [editorContent, setEditorContent] = useState( '' );
	const [initialEditorContent, setInitialEditorContent] = useState( '' );
	// const [editorState, setEditorState] = useState( EditorState.createEmpty() );

	const [videos, setVideos] = useState( [] );
	const [images, setImages] = useState( [] );

	const { register, handleSubmit, reset, formState: { errors } } = useForm();
	const navigate = useNavigate();

	const trapSpacesForRequiredFields = ( value ) => !!value.trim();

	const handleGalleryItemDelete = ( event ) => {
		const target = event.target;
		const galleryId = parseInt( target.dataset.id );
		const filteredGrowerGalleries = grower.growerGalleries.filter( item => item.id !== galleryId );

		setGrower( { growerGalleries: filteredGrowerGalleries } );
	}

	const newImageUpload = ( data ) => {
		setImages( [...images, data] );
	}

	const newVideoUpload = ( data ) => {
		setVideos( [...videos, data] );
	}

	const updateImages = ( newImages ) => {
		setImages( newImages );
	}

	const updateVideos = ( newVideos ) => {
		setVideos( newVideos );
	}

	const updateEditorContent = ( e ) => {
		setEditorContent( e.target.getContent() );
		// console.log( newEditorContent );
		// setEditorContent( newEditorContent );
	}

	const handleFormSubmit = ( data ) => {
		if( data.id !== 0 ) {
			// Update existing grower
			apiClient( {
				method: 'patch',
				url: '/api/v1/growers/' + data.id,
				data: {
					name: data.name,
					farmerEmail: data.farmerEmail,
					location: data.location,
					size: data.size,
					sheepTotal: data.sheepTotal,
					content: editorContent,
					images: images,
					videos: videos,
					owner: data.owner,
					accreditation: data.accreditation
				}
			} )
			.then( response => {					
				if( response.status === 200 && response.data === 1 ) {
					// User message
					toast.success( "Grower updated" );

					// Redirect to all growers
					navigate( '/admin/growers' );
				}
			})
			.catch( handleErrorMessage );
		}	
		else {
			// Create new grower
			setIsLoaded( false );
			apiClient( {
				method: 'post',
				url: '/api/v1/growers',
				data: {
					name: data.name,
					farmerEmail: data.farmerEmail,
					location: data.location,
					size: data.size,
					sheepTotal: data.sheepTotal,
					content: editorContent,
					images: images,
					videos: videos,
					owner: data.owner,
					accreditation: data.accreditation
				}
			} )
			.then( response => {			
				setIsLoaded( true );	
				if( response.status === 201 ) {
					// setUpdateMessage( 'Grower created' );
					toast.success( "Grower created" );
					if( response.data.id ) {
						// Redirect to new grower
						// navigate( '/admin/growers/' + response.data.id );

						// Redirect to all growers
						navigate( '/admin/growers' );
					}
				}
			})
			.catch( handleErrorMessage );
		}
	}

	useEffect( () => {
		if( props.loggedIn ) {
			if( props.id ) {
				apiClient.get( '/api/v1/growers/' + props.id, { params: { includeGrowerFiles: true } } )
				.then( response => {
					// Set form data with the response
					reset( response.data.data );
					// Load HTML into WYSIWYG
					setEditorContent( response.data.data.content );
					setInitialEditorContent( response.data.data.content );
					// const content = htmlToDraft( response.data.data.content );
					// if( content ) {
					// 	const contentState = ContentState.createFromBlockArray( content.contentBlocks );
					// 	setEditorState( EditorState.createWithContent( contentState ) );
					// }
					// Load images and video
					setVideos( response.data.data.videos );
					setImages( response.data.data.images );
					// Indicate existing grower
					setIsNew( false );
					// Set as loaded
					setIsLoaded( true );
				})
				.catch( handleErrorMessage );
			}
			else {
				reset( {
					"id": 0,
					"sheepTotal": 0
				} );

				// Starting content
				setInitialEditorContent( '<h3>Grower Name</h3><p>Grower Description</p>' );

				// Set as loaded
				setIsLoaded( true );
			}
		}
	}, [ props.loggedIn ]);

	if( props.loggedIn && hasError === false ) {
		let growerForm;

		// images.forEach( ( image ) => {
		// 	console.log( image );
		// } );
		
		if( grower ) {
			let growerGalleryItems;
			if( grower.growerGalleries ) {
				growerGalleryItems = grower.growerGalleries.map( ( galleryItem ) =>
					<div key={ galleryItem.id } className="gallery-item">
						<a className={ classNames( ["close", "button"] ) } onClick={ handleGalleryItemDelete } data-id={ galleryItem.id }>x</a>
						<img width="100" src={ galleryItem.imageUrl } />
					</div>
				);
			}
			// const isAdmin = ( grower.role === "admin" );
			growerForm = <form onSubmit={ handleSubmit( handleFormSubmit ) }>
				<label>Grower Name *<br /><input type="text" { ...register( "name", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.name ? "true" : "false" }  /></label>
				<label>Owner<br /><input type="text" { ...register( "owner", { required: false } ) } aria-invalid={ errors.owner ? "true" : "false" }  /></label>
				<label>Farmer Email<br /><input type="text" { ...register( "farmerEmail", { required: false, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ } ) } aria-invalid={ errors.farmerEmail ? "true" : "false" }  /></label>
				<label>Location *<br /><input type="text" { ...register( "location", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.location ? "true" : "false" }   /></label>
				<label>Size *<br /><input type="text" { ...register( "size", { required: true, validate: trapSpacesForRequiredFields } ) } aria-invalid={ errors.size ? "true" : "false" }  /></label>
				<label>No. Sheep *<br /><input type="text" { ...register( "sheepTotal", { required: true, pattern: /^[1-9]\d*$/ } ) } aria-invalid={ errors.sheepTotal ? "true" : "false" }  /></label> 
				<label>Accreditation<br /><input type="text" { ...register( "accreditation", { required: false } ) } aria-invalid={ errors.accreditation ? "true" : "false" }  /></label>
				<label>Overview</label>
				<Editor
          apiKey="wlt0qwfg9unnl4khnfncc4utbkj8q3vqa8hea7q8pi0xh755"
          initialValue={ initialEditorContent }
          init={ {
            branding: false,
            height: 400,
            menubar: true,
            plugins:
              "advcode paste searchreplace autolink directionality visualchars link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textpattern",
            toolbar:
              "formatselect | bold italic underline strikethrough | blockquote | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent table | removeformat",
            image_advtab: false
          } }
          onChange={ updateEditorContent }
        />
				{ ( growerGalleryItems ) ? <div className="gallery-items">{ growerGalleryItems }</div> : '' }
				<div className="files-list">
					<div className="images-list">
						<h3>Images</h3>
						<UploadedFilesList files={ images } handler={ updateImages } />
						<UploadFiles label="Upload File" fileUploaded={ newImageUpload } />
					</div>
					<div className="videos-list">
						<h3>Videos</h3>
						<UploadedFilesList files={ videos } handler={ updateVideos } />
						<UploadFiles label="Upload File" fileUploaded={ newVideoUpload } />
					</div>
				</div>
				<div className="buttons-span">	
					<div className="submit-buttons">
						<Link className={ classNames( ['button', 'cancel'] ) } to={ "/admin/growers/" }>Cancel</Link>
						<button name="submit">{ ( isNew ) ? "Create" : "Submit" }</button>
					</div>
				</div>
			</form>
		}

		return (
			<div className={ classNames( { 'is-loading': !isLoaded }, 'inner-layout', 'admin-layout' ) }>
				<div className="layout-table dashboard-layout-table">
					{ growerForm }
				</div>
			</div>
		);
	}

	return (
		<p>{ message }</p>
	);
};