import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import apiClient from '../services/apiClient';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

const Login = ( props ) => {
	// console.log( props );
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState();

	const handleSubmit = ( e ) => {
		e.preventDefault();
		apiClient.get( '/sanctum/csrf-cookie',
		{ headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		} } )
		.then( response => {
			// console.log( response );
			apiClient.post( '/login', {
				email: email,
				password: password,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				}
			} ).then( response => {
				if( response.status === 204 ) {
					props.login();
					
				}
			} )
			.catch( ( error ) => {
				setMessage( { type: error.code, message: error.response.data.message } );
			} );
		} );
	}
	return (
		<div className="inner-layout login-layout">
      <div className="form-wrapper">
        <h1>Login</h1>
				<form onSubmit={ handleSubmit }>
					{ ( message ) ? <p className={ classNames( [ "form-message", message.type ] ) } >{ message.message }</p> : '' }
					<label>Email address<br />
						<input
							type="email"
							name="email"
							placeholder="Email"
							value={ email }
							onChange={ e => setEmail( e.target.value ) }
							autoComplete="username"
							required
						/>
					</label>
					<label>Password<br />
						<input
							type="password"
							name="password"
							placeholder="Password"
							value={ password }
							onChange={ e => setPassword( e.target.value ) }
							autoComplete="current-password"
							required
						/>
					</label>
					<button type="submit">Login</button>
				</form>
				{/* <Link to="/forgot">Reset your password</Link> */}
      </div>
    </div>
	);
}

export default Login;